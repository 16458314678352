import { useAppStore } from "@/store";
import { useCallback, useState } from "react";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

export const useChatSubscription = (conversationId?: string) => {
  const { store } = useAppStore();
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribeToChat = useCallback(() => {
    if (conversationId && !isSubscribed) {
      store.chatConversations.subscribeToLiveChatConversation(conversationId);
      setIsSubscribed(true);
    }
  }, [conversationId, isSubscribed, store.chatConversations]);

  const unsubscribeFromChat = useCallback(() => {
    if (conversationId) {
      store.chatConversations.unsubscribeFromLiveChatConversation(conversationId);
      setIsSubscribed(false);
    }
  }, [conversationId, store.chatConversations]);

  useEffectOnMount(() => {
    return () => {
      unsubscribeFromChat();
    };
  });

  return {
    subscribeToChat,
    unsubscribeFromChat,
  };
};

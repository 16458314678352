import { mdsColors, mdsFontWeights, mdsFontSizes, mdsSpacings } from "@/design-system/foundations";
import styled from "@emotion/styled";

const LightTag = styled.span`
  background-color: ${mdsColors().grey.x25};
  padding: 1px 4px 3px 4px;
  margin: -2px 0;
  border-radius: ${mdsSpacings().xs};
`;

/**
 * Purely temporary instructions for the experimental Guided Chat feature.
 * Feel free to improve as Guided Chat moves towards full roll-out.
 */
export const GuidedChatInstructions = () => (
  <div
    style={{
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      color: mdsColors().grey.x600,
    }}
  >
    <div
      style={{
        margin: mdsSpacings().md,
        maxWidth: "600px",
      }}
    >
      <h1
        style={{
          fontSize: mdsFontSizes().large,
          fontWeight: mdsFontWeights().bold,
        }}
      >
        Using Guided Chat:
      </h1>
      <ol style={{ paddingLeft: "1em" }}>
        <li>
          <LightTag>@-mention</LightTag> notes or collections to have Mem &ldquo;read&rdquo; them
          and use them as context in its replies. You can also <LightTag>@-mention</LightTag> time
          frames (such as &ldquo;@Yesterday&rdquo; or &ldquo;@Last week&rdquo;) to read all notes
          from that time frame into context.
        </li>
        <li>
          Everything you bring into context remains in context (including all past messages) for the
          entirety of the chat session.
        </li>
        <li>
          There isn&apos;t yet a way to visit past sessions. Once you navigate away from the
          session, if you go back to Guided Chat, you will only be able to create a new session.
        </li>
      </ol>
    </div>
  </div>
);

import { MentionChip } from "@/pages/chat/ChatInput";
import { MemCommonMentionKind } from "@mem-labs/common-editor";
import {
  ChatMessageContextKind,
  NoteMentionContext,
  CollectionMentionContext,
  TimeRangeMentionContext,
  MentionContext,
  ChatMessageContext,
  SearchQueryMentionContext,
} from "@/store/chat/types";
import { SubmitChatMessageSyncOperationChatMessageContextValue } from "@/store/sync/operations/types";

const GROUP_SIZE = 3;

export function makeVisibleChips(availableChips: MentionChip[]) {
  const groupCount = new Set(availableChips.map(chip => chip.groupTitle)).size;

  if (groupCount < 2) return availableChips;

  const groupedChips = availableChips.reduce(
    (acc, chip) => {
      const group = chip.groupTitle || "";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(chip);
      return acc;
    },
    {} as Record<string, MentionChip[]>
  );

  return Object.entries(groupedChips).flatMap(([, group]) => {
    // move featuredItems into place
    const featuredItems = group.filter(item => typeof item.featuredIndex === "number");
    for (const item of featuredItems) {
      const currentIndex = group.indexOf(item);
      const targetIndex = item.featuredIndex!;
      if (currentIndex !== targetIndex && targetIndex < group.length) {
        group.splice(currentIndex, 1);
        group.splice(targetIndex, 0, item);
      }
    }

    return group.slice(0, GROUP_SIZE);
  });
}

export function mentionChipToContext(mention: MentionChip): MentionContext | null {
  switch (mention.kind) {
    case MemCommonMentionKind.Note:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.NoteMention,
      } as NoteMentionContext;
    case MemCommonMentionKind.Collection:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.CollectionMention,
      } as CollectionMentionContext;
    case MemCommonMentionKind.TimeRange: {
      const [start, end] = mention.metadata?.split("-").map(Number) ?? [];
      if (!start || !end) return null;

      return {
        id: mention.id,
        kind: ChatMessageContextKind.TimeRangeMention,
        start: new Date(start * 1000),
        end: new Date(end * 1000),
      } as TimeRangeMentionContext;
    }
    case MemCommonMentionKind.SearchQuery:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.SearchQueryMention,
        query_string: mention.metadata,
      } as SearchQueryMentionContext;
    default:
      return null;
  }
}

export function chatMentionContextToOperationContext(
  context: ChatMessageContext
): SubmitChatMessageSyncOperationChatMessageContextValue {
  switch (context.kind) {
    case ChatMessageContextKind.NoteMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          note_id: context.id,
        },
      };
    case ChatMessageContextKind.CollectionMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          collection_id: context.id,
        },
      };
    case ChatMessageContextKind.TimeRangeMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          time_start: context.start.valueOf() / 1000,
          time_end: context.end.valueOf() / 1000,
        },
      };
    case ChatMessageContextKind.SearchQueryMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          query_string: context.query_string,
        },
      };
    default:
      return {
        context_id: context.id,
        kind: "GLOBAL",
        value: {
          schema_version: 1,
          note_id: context.id,
        },
      };
  }
}

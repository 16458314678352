import { appRoutes } from "@/app/router";
import { AppRoute } from "@/app/router/utils";
import { Maybe } from "@/domains/common/types";
import { APP_CONFIG } from "@/domains/global/config";
import { goBack } from "@/store/navigation/navigation-stack/goBack";
import { goUp } from "@/store/navigation/navigation-stack/goUp";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { isEqual, isString, last } from "lodash-es";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { useEffect } from "react";
import {
  type NavigateFunction,
  type NavigateOptions,
  type To,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router";
import { StateSnapshot, VirtuosoHandle } from "react-virtuoso";
import { urlParamsModule } from "@/modules/url-params";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { HomeParams } from "@/modules/url-params/home-params/types";
import { MemCommonMentionKind } from "@mem-labs/common-editor";
import { goForward } from "@/store/navigation/navigation-stack/goForward";
import { windowModule } from "@/modules/window";
import { arePathsEqual } from "./navigation-stack/comparison";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";

export class AppStoreNavigationStore extends AppSubStore {
  private navigate: NavigateFunction | null = null;

  stack: To[] = [];
  forwardStack: To[] = [];
  virtuoso: VirtuosoHandle | null = null;
  virtuosoStateStack: Maybe<StateSnapshot>[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<this, "navigate" | "navigateTo">(this, {
      navigate: observable,

      stack: observable,
      forwardStack: observable,
      virtuoso: observable,
      virtuosoStateStack: observable,
      restoreVirtuosoStateFrom: computed,

      setVirtuoso: action,
      useSyncNavigation: action,
      pushToStack: action,

      activePath: computed,
      activeSearchQuery: computed,
      matchActivePath: false,
      navigateTo: false,
      canGoBack: computed,
      canGoForward: computed,
      currentItemId: computed,
      isCurrentlyOnCollectionPage: computed,
      isCurrentlyOnNotePage: computed,

      goUpIfActivePathIs: false,
      goUp: false,
      goBack: false,
      goForward: false,
      goToLogOut: false,
      goToLogIn: false,
      goToCollections: false,
      goToChat: false,
      goToGuidedChatHome: false,
      goToGuidedChatConversation: false,
      goToIntegrations: false,
      goToIntegrationsImports: false,
      goToImportFrom1dot0: false,
      goToCollection: false,
      goToNote: false,
      goToTrash: false,
      goToAdmin: false,
      goToSettings: false,
      goToSettingsEmailPreferences: false,
      goToSettingsSubscriptionManagement: false,
      goToSettingsAccountManagement: false,
      goToSettingsDebugAccount: false,
      goToSettingsDebugSandbox: false,
      goToSettingsExports: false,
      goToSettingsAccountMigration: false,
      goToSearch: false,
      goToExplore: false,
      goToExploreTopicDetail: false,
      goToNotes: false,
      goToSavedSearch: false,
      openExternalHelpAndSupport: false,
      goToMention: false,
    });
  }

  setVirtuoso = (virtuoso: VirtuosoHandle | null) => {
    this.virtuoso = virtuoso;
  };

  get restoreVirtuosoStateFrom() {
    return this.virtuosoStateStack[this.virtuosoStateStack.length - 1];
  }

  useSyncNavigation() {
    const navigate = useNavigate();
    this.navigate = navigate;

    const initialLocation = useLocation();

    useEffect(() => {
      if (arePathsEqual(this.activePath, initialLocation.pathname)) {
        return;
      }

      this.pushToStack({
        pathname: initialLocation.pathname,
        search: initialLocation.search,
      });
    }, [initialLocation.pathname, initialLocation.search]);
  }

  pushToStack = (path: To) => {
    this.stack.push(path);
    this.virtuosoStateStack.push(undefined);
  };

  get activePath(): To | undefined {
    return last(this.stack);
  }

  get activeSearchQuery(): string {
    if (!this.activePath || !isString(this.activePath.search)) {
      return "";
    }

    return this.activePath.search;
  }

  get isCurrentlyOnNotePage(): boolean {
    const pathname =
      typeof this.activePath === "string" ? this.activePath : this.activePath?.pathname;

    if (!pathname) {
      return false;
    }

    return !!matchPath("/notes/:noteId", pathname);
  }

  get isCurrentlyOnCollectionPage(): boolean {
    const pathname =
      typeof this.activePath === "string" ? this.activePath : this.activePath?.pathname;

    if (!pathname) {
      return false;
    }

    return !!matchPath("/collections/:collectionId", pathname);
  }

  get currentItemId(): string | undefined {
    const pathname =
      typeof this.activePath === "string" ? this.activePath : this.activePath?.pathname;

    return pathname?.split("/")[2];
  }

  matchActivePath = ({ path }: { path: string }) => {
    if (!this.activePath) {
      return false;
    }

    if (isString(this.activePath)) {
      return matchPath(path, this.activePath);
    }

    if (isString(this.activePath.pathname)) {
      return matchPath(path, this.activePath.pathname);
    }

    return false;
  };

  /**
   * @todo - I wonder if we can modify this to just hook into our utility functions below
   * E.g. we have "goToCollection" push onto the "nav stack", etc.
   *
   * TODO - we also need some more tests/comments about what this is, why it is necessary,
   * and how it works.
   */
  private navigateTo = ({
    appRoute,
    to,
    config,
  }: (
    | {
        appRoute: AppRoute;
        to?: undefined;
      }
    | {
        appRoute?: undefined;
        to: To;
      }
  ) & {
    config?: {
      routerOptions?: NavigateOptions;
      /** resetStack will remove all history. This differs from replace which only replaces the top of the nav stack. */
      resetStack?: boolean;
    };
  }) => {
    if (!this.navigate) {
      return;
    }

    const targetPath = appRoute ? appRoute.path : to;
    const replaceRoute = config?.routerOptions?.replace ?? false;
    const resetStack = config?.resetStack ?? false;

    const topOfStack = last(this.stack);

    if (isEqual(topOfStack, targetPath)) {
      return;
    }

    if (replaceRoute) {
      // There's no need to update virtuoso state if we're replacing the current route.
      this.stack.pop();
      this.stack.push(targetPath);
      this.navigate(targetPath, { replace: true, ...config });
      return;
    }

    if (resetStack) {
      runInAction(() => {
        this.stack = [];
        this.virtuosoStateStack = [];
      });
    }

    // When navigating to a new route we overwrite the top virtuoso state and push an empty state
    // for the new route.
    this.virtuoso?.getState(state => {
      this.virtuosoStateStack.pop();
      this.virtuosoStateStack.push(state);
    });

    // When navigating forwards to a new route, we clear the forward stack.
    runInAction(() => {
      this.forwardStack = [];
    });

    this.store.sidePanel.setSidePanelFocused(false);
    this.pushToStack(targetPath);
    this.navigate(targetPath, config?.routerOptions);
  };

  goUpIfActivePathIs = (path: To) => {
    if (this.activePath === path) {
      this.goUp();
    }
  };

  goUp = () => {
    setTimeout(() => {
      if (!this.navigate) return;
      goUp(this);
    }, 0);
  };

  goBack = () => {
    setTimeout(() => {
      runInAction(() => {
        if (!this.navigate) return;
        goBack(this, this.navigate);
      });
    }, 0);
  };

  goForward = () => {
    setTimeout(() => {
      runInAction(() => {
        if (!this.navigate) return;
        goForward(this, this.navigate);
      });
    }, 0);
  };

  get canGoBack() {
    return this.stack.length > 1;
  }

  get canGoForward() {
    return this.forwardStack.length > 0;
  }

  /**
   * These are all the helper functions used for navigation in our app.
   *
   * We strongly prefer this over calling "this.navigateTo({appRoutes.home({}).path);" directly,
   * because it allows us to have more control over the "navigation stack".
   *
   * E.g. in certain cases,
   *   - we want to modify the input arguments
   *   - we want to augment the path name
   *   - we want to keep track of certain paths being navigated to
   *   - ...
   *
   * (Yes it is a lot of boilerplate, but it is worth it to have "one way" to do it.)
   */

  goToLogOut = () => {
    this.navigateTo({ appRoute: appRoutes.logOut({}) });
  };

  goToLogIn = () => {
    this.navigateTo({ appRoute: appRoutes.logIn({}) });
  };

  goToCollections = () => {
    this.navigateTo({ appRoute: appRoutes.collections({}) });
  };

  goToChat = () => {
    this.store.sidePanel.close();
    this.navigateTo({ appRoute: appRoutes.chat({}) });
  };

  goToGuidedChatHome = () => {
    this.store.sidePanel.close();
    this.navigateTo({ appRoute: appRoutes.guidedChatHome({}) });
  };

  goToGuidedChatConversation = ({ conversationId }: { conversationId: string }) => {
    this.store.sidePanel.close();
    this.navigateTo({ appRoute: appRoutes.guidedChatConversation({ params: { conversationId } }) });
  };

  goToExplore = () => {
    this.navigateTo({ appRoute: appRoutes.explore({}) });
  };

  goToExploreTopicDetail = ({ topicId }: { topicId: string }) => {
    this.navigateTo({ appRoute: appRoutes.exploreTopicDetail({ params: { topicId } }) });
  };

  goToIntegrations = () => {
    this.navigateTo({ appRoute: appRoutes.integrations({}) });
  };

  goToIntegrationsImports = () => {
    this.navigateTo({ appRoute: appRoutes.integrationsImports({}) });
  };

  goToImportFrom1dot0 = () => {
    this.navigateTo({ appRoute: appRoutes.importFrom1dot0({}) });
  };

  goToCollection = ({ collectionId }: { collectionId: string }) => {
    this.navigateTo({ appRoute: appRoutes.collectionsView({ params: { collectionId } }) });
  };

  goToNote = ({
    noteId,
    highlightText,
    autoFocus,
    resetStack,
  }: {
    noteId: string;
    highlightText?: string | null;
    autoFocus?: boolean;
    resetStack?: boolean;
  }) => {
    this.navigateTo({
      appRoute: appRoutes.notesView({ params: { noteId } }),
      config: { routerOptions: { state: { highlightText, autoFocus } }, resetStack },
    });
  };

  goToMention = ({
    id,
    mentionKind,
    url,
    highlightText,
    keys,
  }: {
    url: string;
    mentionKind: MemCommonMentionKind;
    id: string;
    highlightText?: string;
    keys?: Parameters<INotesViewPageStore["goToMention"]>[0]["keys"];
  }) => {
    // Other clients and systems MAY store IDs in all caps, so we normalize them to lowercase for the web client router.
    const lowerCaseId = id.toLowerCase();

    switch (mentionKind) {
      case MemCommonMentionKind.Collection: {
        const openInSidePanel = keys?.altKey && this.store.sidePanel.canRenderSplitView;
        const navigator = openInSidePanel ? this.store.sidePanel : this.store.navigation;
        return navigator.goToCollection({ collectionId: lowerCaseId });
      }
      case MemCommonMentionKind.Note: {
        const openInSidePanel = keys?.altKey && this.store.sidePanel.canRenderSplitView;
        const navigator = openInSidePanel ? this.store.sidePanel : this.store.navigation;
        return navigator.goToNote({ noteId: lowerCaseId, highlightText });
      }
      default: {
        windowModule.openInNewTab({ url: new URL(url) });
      }
    }
  };

  goToTrash = () => {
    this.navigateTo({ appRoute: appRoutes.trash({}) });
  };

  goToAdmin = () => {
    this.navigateTo({ appRoute: appRoutes.admin({}) });
  };

  goToSettings = () => {
    this.navigateTo({ appRoute: appRoutes.settings({}) });
  };

  goToSettingsEmailPreferences = () => {
    this.navigateTo({ appRoute: appRoutes.settingsEmailPreferences({}) });
  };

  goToSettingsSubscriptionManagement = () => {
    this.navigateTo({ appRoute: appRoutes.settingsSubscriptionManagement({}) });
  };

  goToSettingsAccountManagement = () => {
    this.navigateTo({ appRoute: appRoutes.settingsAccountManagement({}) });
  };

  goToSettingsDebugAccount = () => {
    this.navigateTo({ appRoute: appRoutes.settingsDebugAccount({}) });
  };

  goToSettingsDebugSandbox = () => {
    this.navigateTo({ appRoute: appRoutes.settingsDebugSandbox({}) });
  };

  goToSettingsExports = () => {
    this.navigateTo({ appRoute: appRoutes.settingsExports({}) });
  };

  goToSettingsAccountMigration = () => {
    this.navigateTo({ appRoute: appRoutes.settingsAccountMigration({}) });
  };

  goToSearch = ({
    searchParams,
    config,
  }: {
    searchParams: Partial<SearchEngineParams>;
    config?: {
      replace?: boolean;
    };
  }) => {
    const searchQueryStr = urlParamsModule.search.stringify({
      searchParams,
    });

    this.navigateTo({
      to: {
        pathname: appRoutes.search({}).path,
        search: searchQueryStr,
      },
      config: { routerOptions: { replace: config?.replace } },
    });
  };

  goToNotes = (
    {
      homeParams,
      config,
    }: {
      homeParams: Partial<HomeParams>;
      config?: {
        replace?: boolean;
      };
    } = { homeParams: {} }
  ) => {
    const searchQueryStr = urlParamsModule.home.stringify({
      homeParams,
    });

    this.navigateTo({
      to: {
        pathname: appRoutes.notesList({}).path,
        search: searchQueryStr,
      },
      config: { routerOptions: { replace: config?.replace } },
    });
  };

  goToSavedSearch = ({ savedSearchId }: { savedSearchId: string }) => {
    const savedSearchObservable = this.store.savedSearches.getSavedSearchObservableById({
      savedSearchId,
    });

    if (savedSearchObservable) {
      this.goToSearch({ searchParams: { ...savedSearchObservable.savedSearchParams } });
    }
  };

  openExternalHelpAndSupport = () => {
    windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.HELP_AND_SUPPORT) });
  };
}

import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsFloatingDropdownContent } from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MDSSwitchFloaty } from "@/mds/components/buttons/switch-floaty";
import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
  mdsSpacings,
} from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import {
  MemCommonRichTextInputInstance,
  MemCommonRichTextInputInitializer,
  MemCommonRichTextInputActionKind,
  MemCommonMentionKeyDownKind,
  MemCommonMentionKind,
  MemCommonRichTextInputEvent,
  MemCommonRichTextInputEventKind,
  MemCommonRichTextInput,
  MemCommonEditorTheme,
  MemCommonCloseMentionCondition,
  MemCommonEditorSlashCommand,
} from "@mem-labs/common-editor";
import { observer } from "mobx-react-lite";
import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useLayoutEffect,
  useEffect,
  MouseEventHandler,
} from "react";
import useMeasure from "react-use/lib/useMeasure";
import styled from "@emotion/styled";
import { useDebounceCallback } from "usehooks-ts";
import { useAsync } from "@/modules/use-async";
import { Maybe } from "@/domains/common/types";
import { CommandIds } from "@/store/chat/ChatHistory";
import { ZIndex } from "@/domains/design/constants";
import { makeVisibleChips } from "@/pages/chat/lib";

export interface MentionChip {
  id: string;
  kind?: MemCommonMentionKind;
  className?: MdsDropdownButtonItem["className"];
  iconKind: MdsDropdownButtonItem["iconKind"];
  label: MdsDropdownButtonItem["label"];
  subtitle?: string;
  content?: MdsDropdownButtonItem["content"];
  beforeSelection?: () => Promise<void>;
  alwaysVisible?: boolean;
  groupTitle?: string;
  action?: MemCommonEditorSlashCommand | (() => Promise<Maybe<MemCommonEditorSlashCommand>>);
  featuredIndex?: number;
  metadata?: string;
}

export interface ChatInputProps {
  className?: string;
  conversationId?: string;
  getAvailableChips: (mentionQuery: string) => Promise<MentionChip[]>;
  onHeight?: (height: number) => void;
  onSubmit: (payload: {
    markdownContent: string;
    htmlContent: string;
    isGuidedChat_experiment: boolean;
    agentMode: boolean;
  }) => void;
  onDraftChange?: (draft?: string) => void;
  inSidePanel?: boolean;
  isGuidedChat_experiment: boolean;
  getDraftMessage?: () => string | undefined;
  addMentionToContexts: (mention: MentionChip) => void;
  removeMentionFromContexts: (mention: MentionChip) => void;
}

export const ChatInput = observer<ChatInputProps>(function RichTextAreaInput({
  className,
  getAvailableChips,
  onHeight,
  onSubmit,
  onDraftChange,
  isGuidedChat_experiment,
  getDraftMessage,
  addMentionToContexts,
  // removeMentionFromContexts,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const isAgentModeRef = useRef(false);

  const latestDraftMessage = useRef<string | undefined>();
  const draftContentSentLock = useRef(false); // prevent sending the initial message multiple times
  const chatInputRef = useRef<MemCommonRichTextInputInstance>(null);
  const chatInputInitializer: MemCommonRichTextInputInitializer = useMemo(() => {
    return async () => ({
      autoFocus: true,
      autoSubmit: true,
      theme: MemCommonEditorTheme.Light,
    });
  }, []);

  const sendSubmitAction = useCallback((event?: MouseEvent) => {
    event?.stopPropagation();
    chatInputRef.current?.dispatchAction({
      kind: MemCommonRichTextInputActionKind.SendContents,
      payload: null,
    });
  }, []);

  const [mentionQuery, setMentionQuery] = useState("");

  const lockedMouseYRef = useRef(0);
  const mouseYRef = useRef(0);
  const handleMouseMove: MouseEventHandler<HTMLDivElement> = useDebounceCallback(
    event => {
      mouseYRef.current = event.clientY;
      if (Math.abs(lockedMouseYRef.current - mouseYRef.current) > 2) {
        // Mouse was moved, unlock hover to select.
        lockedMouseYRef.current = 0;
      }
    },
    20,
    { maxWait: 40 }
  );

  const [keyboardSelectionChipIndex, setKeyboardSelectedChipIndex] = useState(-1);
  const [
    scrollIntoViewMentionKeyboardSelectionChipIndex,
    setScrollIntoViewMentionKeyboardSelectionChipIndex,
  ] = useState(false);

  const { result: availableChips, invoke } = useAsync({
    fn: getAvailableChips,
    options: { defaultValue: [] },
  });

  useEffect(() => {
    invoke(mentionQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionQuery]);

  useEffect(() => {
    // Reset mentions menu.
    setKeyboardSelectedChipIndex(availableChips?.length ? 0 : -1);
    setScrollIntoViewMentionKeyboardSelectionChipIndex(!!availableChips?.length);
    lockedMouseYRef.current = mouseYRef.current;
  }, [availableChips]);

  useEffect(() => {
    // as soon as we can, send the draft content to the chat input
    const interval = setInterval(() => {
      // only do this all if a getDraftMessage prop was provided
      if (!getDraftMessage) {
        clearInterval(interval);
        return;
      }

      // wait until the chat input ref is available and we can send actions to it
      const dispatchAction = chatInputRef.current?.dispatchAction;
      if (!dispatchAction) return;

      const draftMessage = getDraftMessage();
      if (!draftMessage) {
        clearInterval(interval);
        return;
      }

      // if the initial message has already been sent, don't do anything else
      if (draftContentSentLock.current) {
        clearInterval(interval);
        return;
      }

      if (draftMessage === latestDraftMessage.current) {
        // This component can get re-rendered for reasons other than the content changing,
        // such as selection change, @-mention menus appearing, etc.
        // We skip those updates to avoid spamming Common Editor with false updates.
        clearInterval(interval);
        return;
      }

      // send the initial message to the chat input
      draftContentSentLock.current = true;
      latestDraftMessage.current = draftMessage;

      dispatchAction({
        kind: MemCommonRichTextInputActionKind.SetEditorContent,
        payload: {
          content: draftMessage,
        },
      });
    }, 100);

    return () => {
      draftContentSentLock.current = false;
      clearInterval(interval);
    };
  });

  const availableChipsRef = useRef(availableChips);
  availableChipsRef.current = availableChips;

  const keyboardSelectionChipIndexRef = useRef(keyboardSelectionChipIndex);
  keyboardSelectionChipIndexRef.current = keyboardSelectionChipIndex;

  const handleSelectMention = useCallback(
    // derp 1
    async (option?: MentionChip) => {
      const dispatchAction = chatInputRef.current?.dispatchAction;
      if (!dispatchAction || !availableChipsRef.current) return;

      const mention = (() => {
        if (option) return option;

        const theseChips = isGuidedChat_experiment
          ? makeVisibleChips(availableChipsRef.current)
          : availableChipsRef.current;
        return theseChips[Math.max(keyboardSelectionChipIndexRef.current, 0)];
      })();

      if (!mention) return;

      const action = typeof mention.action === "function" ? await mention.action() : mention.action;
      if (!action && typeof mention.action === "function") return;

      mention.beforeSelection?.();

      addMentionToContexts(mention);

      dispatchAction({
        kind: MemCommonRichTextInputActionKind.CloseMention,
        payload: {
          id: mention.id,
          kind: mention.kind ?? MemCommonMentionKind.Note,
          label: mention.label,
          action,
          metadata: mention.metadata,
        },
      });
    },
    [addMentionToContexts, isGuidedChat_experiment]
  );

  const handleAgentModeSwitch = (val: boolean) => {
    isAgentModeRef.current = val;
  };

  const contentList = useMemo<MdsDropdownContentList>(() => {
    if (!availableChips) return { items: [] };

    const theseChips = isGuidedChat_experiment ? makeVisibleChips(availableChips) : availableChips;
    const scrollableItems: MdsDropdownItem[] = [];
    const scrollable: MdsDropdownItem = {
      id: "scrollable",
      kind: MdsDropdownItemKind.ScrollableSection,
      items: scrollableItems,
      className: scrollableMentionStyles,
    };
    const contentList: MdsDropdownContentList = {
      items: [],
    };
    const unsupportedCommandIds: string[] = [CommandIds.InsertFile, CommandIds.InsertImage];
    let lastGroupTitle = "";
    theseChips.forEach((mention, index) => {
      if (unsupportedCommandIds.includes(mention.id)) return;

      let items = scrollableItems;
      if (mention.alwaysVisible && scrollableItems.length) {
        items = contentList.items;
        items.push({
          id: mention.id + "-separator",
          kind: MdsDropdownItemKind.Divider,
        });
      }
      const { groupTitle } = mention;
      if (groupTitle && groupTitle !== lastGroupTitle) {
        items.push({
          id: `group-${groupTitle}`,
          kind: MdsDropdownItemKind.Detail,
          text: groupTitle,
          className: groupTitleStyles,
        });
        lastGroupTitle = groupTitle;
      }
      items.push({
        id: mention.id,
        kind: MdsDropdownItemKind.Button,
        iconKind: mention.iconKind,
        content: mention.content,
        label: mention.label,
        scrollIntoView:
          scrollIntoViewMentionKeyboardSelectionChipIndex && index === keyboardSelectionChipIndex,
        className: cx(
          mention.className,
          index === keyboardSelectionChipIndex ? selectedOptionStyles : noHoverStyles
        ),
        onClick: () => {
          handleSelectMention(mention);
        },
      });
    });

    const mentionContent = mentionQuery.slice(1).trim().toLowerCase();

    if (scrollableItems.length && !mentionQuery.startsWith("/") && !isGuidedChat_experiment) {
      const taggingCollection = mentionQuery.startsWith("#");
      const mentionTypeName = taggingCollection ? "collection" : "note";
      const text = `${taggingCollection ? "Tag" : "Mention"} a${!mentionContent.length ? " recent" : ""} ${mentionTypeName}`;
      scrollableItems.unshift({
        id: "header",
        kind: MdsDropdownItemKind.Detail,
        className: detailPaddingStyles,
        text,
      });
    }
    if (scrollableItems.length) {
      contentList.items.unshift(scrollable);
    }
    return contentList;
  }, [
    availableChips,
    handleSelectMention,
    isGuidedChat_experiment,
    keyboardSelectionChipIndex,
    mentionQuery,
    scrollIntoViewMentionKeyboardSelectionChipIndex,
  ]);

  useEffect(() => {
    const dispatchAction = chatInputRef.current?.dispatchAction;
    if (!dispatchAction || !mentionQuery) return;

    dispatchAction({
      kind: MemCommonRichTextInputActionKind.CloseMention,
      payload: {
        condition: contentList.items.length
          ? MemCommonCloseMentionCondition.RenderingResults
          : MemCommonCloseMentionCondition.NoResults,
      },
    });
  }, [contentList, mentionQuery]);

  const handleDropdownHover = useCallback(({ itemId }: { itemId?: string }) => {
    // Lock hover to select until mouse is moved enough.
    // Since it's assigned to mouseenter/leave, this only takes effect when a new element is hovered.
    if (lockedMouseYRef.current) return;

    setKeyboardSelectedChipIndex(availableChipsRef.current?.findIndex(e => e.id === itemId) ?? -1);
    setScrollIntoViewMentionKeyboardSelectionChipIndex(false);
  }, []);

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  useLayoutEffect(() => {
    onHeight?.(height + 2 * CHAT_INPUT_PADDING);
  }, [height, onHeight]);

  const [mentionClientRect, setMentionClientRect] = useState<DOMRect>();

  const chatInputEventHandler = useCallback(
    (event: MemCommonRichTextInputEvent) => {
      console.debug(`[ChatInput] Event ${JSON.stringify(event)}`, undefined, 2);

      if (
        event.kind !== "focus-changed" &&
        event.kind !== "content-height" &&
        !(event.kind === "selection-changed" && !event.payload) // selection-changed with no payload object fires when the editor is not fully mounted, so we don't trust the contents at this time
      ) {
        const editorHtml = chatInputRef.current?.editor.getHTML();
        // run callback if the editor content has changed
        if (editorHtml !== latestDraftMessage.current) {
          latestDraftMessage.current = editorHtml;
          onDraftChange?.(editorHtml);
        }
      }

      switch (event.kind) {
        case MemCommonRichTextInputEventKind.Contents: {
          onSubmit({
            ...event.payload,
            isGuidedChat_experiment,
            agentMode: isAgentModeRef.current,
          });
          break;
        }
        case MemCommonRichTextInputEventKind.MentionUpdateQuery: {
          setMentionQuery(event.payload.mentionQuery);
          setMentionClientRect(event.payload.clientRect ?? undefined);
          break;
        }
        case MemCommonRichTextInputEventKind.MentionKeyDown: {
          const availableChips = availableChipsRef.current;
          if (!availableChips) break;

          switch (event.payload.kind) {
            case MemCommonMentionKeyDownKind.ArrowDown: {
              lockedMouseYRef.current = mouseYRef.current;
              setKeyboardSelectedChipIndex(index => {
                if (index < 0) return 0;
                return (index + 1 + availableChips.length) % availableChips.length;
              });
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.ArrowUp: {
              lockedMouseYRef.current = mouseYRef.current;
              setKeyboardSelectedChipIndex(index => {
                if (index < 0) return availableChips.length - 1;
                return (index - 1 + availableChips.length) % availableChips.length;
              });
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.Enter: {
              if (availableChips.length) {
                handleSelectMention();
              }
              break;
            }
          }
          break;
        }
        case MemCommonRichTextInputEventKind.IsEmpty: {
          setIsEmpty(event.payload.isEmpty);
          break;
        }
      }
    },
    [handleSelectMention, isGuidedChat_experiment, onDraftChange, onSubmit]
  );

  return (
    <div style={{ position: "relative" }}>
      <Container className={className} ref={ref} onMouseMoveCapture={handleMouseMove}>
        <InputContainer>
          <Expand>
            <Scrollable>
              <MemCommonRichTextInput
                richTextInputEventHandler={chatInputEventHandler}
                richTextInputInitializer={chatInputInitializer}
                richTextInputInstanceRef={chatInputRef}
              >
                <MdsFloatingDropdownContent
                  zIndex={ZIndex.MentionsMenu}
                  clientRect={mentionClientRect}
                  placement="top-start"
                  onHover={handleDropdownHover}
                  contentListClassName={
                    mentionQuery.startsWith("/")
                      ? insertMenuContentClassName
                      : mentionsListContentClassName
                  }
                  contentList={contentList}
                />
              </MemCommonRichTextInput>
            </Scrollable>
          </Expand>
          <svg width="0" height="0" className="hidden">
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="7.03%" stopColor="#EB2487" />
              <stop offset="93.99%" stopColor="#F93939" />
            </linearGradient>
          </svg>
          <BottomAligner>
            <MdsIconButton
              isDisabled={isEmpty}
              iconStyles={isEmpty ? undefined : sendIconStyles}
              iconKind={isEmpty ? MdsIconKind.SendAlt : MdsIconKind.Send}
              onClick={sendSubmitAction}
            />
          </BottomAligner>
        </InputContainer>
        {isGuidedChat_experiment && (
          <AgentModeContainer>
            <MDSSwitchFloaty
              label="Look at any relevant notes"
              defaultSelected={isAgentModeRef.current}
              onChange={handleAgentModeSwitch}
              iconKind={MdsIconKind.Robot}
            />
          </AgentModeContainer>
        )}
      </Container>
    </div>
  );
});

const sendIconStyles = css({
  "> path": {
    fill: `url(#gradient)`,
  },
});

const CHAT_INPUT_PADDING = 12;

const mentionsListContentClassName = css({
  maxHeight: "500px",
  width: "340px",
  maxWidth: "calc(100% - 20px)",
});

const insertMenuContentClassName = css({
  background: mdsColors().white,
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: mdsSpacings().sm,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  maxWidth: "calc(100% - 20px)",
  padding: "4px",
  width: "288px",
});

const Container = styled.div({
  borderRadius: "8px",
  border: `1px solid #F3F4F6`,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  padding: `${CHAT_INPUT_PADDING}px`,
  width: "100%",
});

const InputContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const Expand = styled.div({
  flex: 1,
});

const Scrollable = styled.div(({ theme }) => ({
  flex: 1,
  height: "fit-content",
  maxHeight: `calc(6 * ${theme.lineHeights.medium})`,
  overflowY: "auto",

  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const selectedOptionStyles = css({
  background: mdsColors().grey.x25,
});

const noHoverStyles = css({
  "&:hover": {
    background: "unset",
  },
});

const BottomAligner = styled.div({
  alignItems: "flex-end",
  display: "flex",
  height: "100%",
  width: "fit-content",
});

const scrollableMentionStyles = css({
  maxHeight: 220,
});

const detailPaddingStyles = css({
  padding: "8px 0 4px 10px",
});

const groupTitleStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
  padding: `${mdsSpacings().sm} ${mdsSpacings().sm} ${mdsSpacings().xs} ${mdsSpacings().sm} `,
});

const AgentModeContainer = styled.div({
  alignItems: "center",
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  position: "absolute",
  right: CHAT_INPUT_PADDING + 4,
  top: -38,
  pointerEvents: "none",
  "& > *": {
    pointerEvents: "auto",
  },
});

import { ChatView } from "@/components/chat/ChatView";
import { useChatSubscription } from "@/components/chat/use-chat-subscription";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface ChatPageProps {}

export const ChatPage: FC<ChatPageProps> = observer(() => {
  const { store } = useAppStore();
  const conversationId = store.chatConversations.primaryChatConversation
    ? store.chatConversations.primaryChatConversation.id
    : undefined;

  const { subscribeToChat } = useChatSubscription(conversationId);

  return (
    <ChatView chatHistory={store.chatMessages.chatHistory} subscribeToChat={subscribeToChat} />
  );
});

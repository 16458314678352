import { DateTime } from "luxon";

export enum InboundSyncStatus {
  Bootstrapping = "BOOTSTRAPPING",
  PerformingInitialSync = "PERFORMING_INITIAL_SYNC",
  Syncing = "SYNCING",
  Idle = "IDLE",
  PausedOffline = "PAUSED_OFFLINE",
}

export enum OutboundSyncStatus {
  Idle = "IDLE",
  Syncing = "SYNCING",
  Paused = "PAUSED",
}

export type InboundSyncState = {
  status: InboundSyncStatus;
  info: {
    lastSyncedAt: DateTime | undefined;
  };
};

export type OutboundSyncState = {
  status: OutboundSyncStatus;
  info: {
    lastSyncedAt: DateTime | undefined;
    pendingOperationCount: number;
    earliestPendingOperationCommittedAt: DateTime | undefined;
  };
};

export type SyncState = {
  status: OutboundSyncStatus;
  pendingOperationCount: number;
  inboundLastSyncedAt: DateTime | undefined;
  outboundLastSyncedAt: DateTime | undefined;
};

import { MdsItemListSize } from "@/design-system/components/item-list/types";
import { MdsItemListOtherRowData } from "@/design-system/components/item-list/types/MdsItemListRowTypes";
import { css } from "@/domains/emotion";

export const MdsItemListOtherRow = ({
  data,
  className,
}: {
  data: MdsItemListOtherRowData;
  className?: string;
}) => {
  const { size = MdsItemListSize.Medium } = data;
  const rowSizeStyle = rowSizeStyleMapping[size];
  const combinedClassName = css(rowSizeStyle, className);

  return <div className={combinedClassName}>{data.payload.children}</div>;
};

const rowSizeStyleMapping: { [key in MdsItemListSize]: string } = {
  [MdsItemListSize.Medium]: css({
    height: 48,
    lineHeight: "48px",
  }),
  [MdsItemListSize.Large]: css({
    height: 56,
    lineHeight: "56px",
  }),
  [MdsItemListSize.XLarge]: css({
    minHeight: "60px",
    lineHeight: "60px",
  }),
};

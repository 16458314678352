import { Uuid } from "@/domains/global/identifiers";
import { MINIMUM_IMPORT_DURATION_SECONDS } from "@/store/account-transition/constants";
import { IntervalObservable } from "@/store/common";
import { DataImportSyncModelData } from "@/store/data-imports/types";

import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

import { WithAppStore } from "@/store/types";
import { DateTime, Duration } from "luxon";
import { computed, makeObservable, observable } from "mobx";

export class DataImportObservable extends BaseSyncModel<DataImportSyncModelData> {
  public modelKind = SyncModelKind.DataImport;

  _isRunningInterval: IntervalObservable;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<DataImportSyncModelData>;
  } & WithAppStore) {
    super({ id, data, store });

    this._isRunningInterval = new IntervalObservable({
      duration: Duration.fromObject({ seconds: 5 }),
    });

    makeObservable(this, {
      _isRunningInterval: observable,
      modelKind: observable,
      ownedBySpaceAccountId: computed,
      kind: computed,
      startedAt: computed,
      endedAt: computed,
      endedWithError: computed,
      isRunning: computed,
    });
  }

  get ownedBySpaceAccountId() {
    return this.modelData.owned_by_space_account_id;
  }

  get kind() {
    return this.modelData.kind;
  }

  get startedAt() {
    return this.modelData.started_at;
  }

  get endedAt() {
    return this.modelData.ended_at;
  }

  get endedWithError() {
    return this.modelData.ended_with_error;
  }

  get isRunning() {
    if (!this.startedAt) {
      return false;
    }
    /**
     * We check and see if it started within the minimum duration window.
     * (See the doc-comment on `MINIMUM_IMPORT_DURATION_SECONDS` for more details.)
     */
    const now = this._isRunningInterval.value;

    const secondsSinceStart = Math.abs(DateTime.fromISO(this.startedAt).diff(now).as("seconds"));
    if (secondsSinceStart < MINIMUM_IMPORT_DURATION_SECONDS) {
      return true;
    }

    /**
     * If it hasn't ended yet, then it's still running.
     */
    if (!this.endedAt) {
      return true;
    }

    return false;
  }
}

import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { ChatView } from "@/components/chat/ChatView";
import { NoteEditor } from "@/pages/notes-view/NoteEditor";
import { SidePanelRouteType } from "@/store/routing/types";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { CopilotView } from "@/components/copilot/CopilotView";
import { ExpandedRelatedCard } from "@/components/copilot/ExpandedRelatedCard";
import { CollectionsInSidePanel } from "@/pages/collections-view/CollectionsInSidePanel";
import { useChatSubscription } from "@/components/chat/use-chat-subscription";

export const SidePanelRouter = observer(function SidePanelRouter() {
  const { store } = useAppStore();
  const { activeRoute, isSidePanelOpen, activeTabId } = store.sidePanel;
  const isCopilotEnabled = true;
  const conversationId = store.chatConversations.primaryChatConversation?.id;
  const { subscribeToChat } = useChatSubscription(conversationId);

  switch (activeRoute.type) {
    case SidePanelRouteType.NOTE_DETAIL: {
      const noteObservable = store.notes.get(activeRoute.noteId);
      if (!noteObservable) {
        return (
          <NotFoundPage
            entityType={EntityNotFoundType.Note}
            withBackButton={store.sidePanel.goUp}
          />
        );
      }
      return (
        <NoteEditor
          key={activeTabId}
          isSidePanelOpen={isSidePanelOpen}
          chatHistory={store.chatMessages.chatHistory}
          noteObservable={noteObservable}
          myAccount={store.account.myAccount}
          goToMention={store.sidePanel.goToMention}
          inSidePanel
        />
      );
    }

    case SidePanelRouteType.COLLECTION: {
      return <CollectionsInSidePanel />;
    }

    case SidePanelRouteType.CHAT:
      return (
        <ChatView
          key={activeTabId}
          chatHistory={store.chatMessages.chatHistory}
          inSidePanel
          subscribeToChat={subscribeToChat}
        />
      );

    case SidePanelRouteType.COPILOT:
      if (activeRoute.card) {
        return <ExpandedRelatedCard card={activeRoute.card} />;
      }
      if (isCopilotEnabled) {
        return <CopilotView />;
      }
      return null;
  }
});

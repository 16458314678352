import { MdsHoverContentGroup } from "@/design-system/components/hover-content/MdsHoverContentGroup";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { mdsColors, mdsSpacings, mdsSidebarWidths } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import styled from "@emotion/styled";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { MdsHorizontalDivider } from "@/design-system/components/divider";
import {
  MdsHoverContent,
  MdsHoverContentPlacement,
} from "@/design-system/components/hover-content/MdsHoverContent";
import { FavoritesList } from "@/components/layout/components/FavoritesList";
import { RecentsList } from "@/components/layout/components/RecentsList";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { SidebarDropdownMenu } from "@/components/layout/components/sidebar/SidebarDropdownMenu";
import { css } from "@/domains/emotion";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { ProfileVariant } from "@/components/layout/components/account-profile/types";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { EventContext } from "@/domains/metrics/context";
import { isMemAccount } from "@/store/contacts/isMemAccount";

export const SidebarCollapsed = observer(() => {
  const { store } = useAppStore();

  const isChatPath = useMatch(appRoutes.chat({ starPattern: false }));
  const isGuidedChatPath_experimental = useMatch(appRoutes.guidedChatHome({ starPattern: true }));
  const isNotesPath = useMatch(appRoutes.notesList({ starPattern: false }));
  const isCollectionsPath = useMatch(appRoutes.collections({ starPattern: false }));

  const isTouchDevice = store.interface.isTouchDevice;
  const { profileDisplayName, profilePhotoUrl } = store.account.myAccount;

  const hoverGroup = useMemo(() => new MdsHoverContentGroup(), []);
  const closeAll = useCallback(() => hoverGroup.hideAll({}), [hoverGroup]);

  return (
    <SidebarWrapper>
      <SidebarMainWrapper>
        <MdsHoverContent
          isTouchDevice={isTouchDevice}
          group={hoverGroup}
          content={() => <SidebarDropdownMenu className={dropdownStyles} displayAccountInfo />}
          placement={MdsHoverContentPlacement.Right}
        >
          <AccountProfileImageContent
            size={ProfileSize.Standard}
            photoUrl={profilePhotoUrl}
            displayName={profileDisplayName}
            variant={ProfileVariant.Rounded}
            showSyncStatus
          />
        </MdsHoverContent>
        <ComposeIconButton
          iconKind={MdsIconKind.ComposeInline}
          isDisabled={store.notes.newNoteIsBeingCreated}
          onClick={() => store.notes.composeNewNote({ eventContext: EventContext.GlobalActions })}
          variant={MdsIconButtonVariant.Outlined}
          tooltipConfig={{
            content: "Create note",
            placement: MdsTooltipPlacement.Right,
          }}
        />
        <MdsIconButton
          iconKind={MdsIconKind.Message}
          onClick={store.navigation.goToChat}
          className={isChatPath ? activeContentListItemStyles : undefined}
          tooltipConfig={{
            content: "Chat",
            placement: MdsTooltipPlacement.Right,
          }}
        />
        {isMemAccount(store.account.myAccount) && (
          <MdsIconButton
            iconKind={MdsIconKind.MessageExclamation}
            onClick={store.navigation.goToGuidedChatHome}
            className={isGuidedChatPath_experimental ? activeContentListItemStyles : undefined}
            tooltipConfig={{
              content: "Guided Chat",
              placement: MdsTooltipPlacement.Right,
            }}
          />
        )}
        <MdsHorizontalDivider />
        <MdsIconButton
          iconKind={MdsIconKind.Notebook}
          onClick={() => store.navigation.goToNotes()}
          className={isNotesPath ? activeContentListItemStyles : undefined}
          tooltipConfig={{
            content: "Notes",
            placement: MdsTooltipPlacement.Right,
          }}
        />
        <MdsIconButton
          iconKind={MdsIconKind.Collection}
          onClick={store.navigation.goToCollections}
          className={isCollectionsPath ? activeContentListItemStyles : undefined}
          tooltipConfig={{
            content: "Collections",
            placement: MdsTooltipPlacement.Right,
          }}
        />
        <MdsHorizontalDivider />
        <MdsHoverContent
          isTouchDevice={isTouchDevice}
          group={hoverGroup}
          content={() => (
            <FavoritesList onClick={closeAll} animate={false} className={dropdownStyles} />
          )}
          placement={MdsHoverContentPlacement.Right}
        >
          <MdsIconButton iconKind={MdsIconKind.Thumbtack} />
        </MdsHoverContent>
        <MdsHoverContent
          isTouchDevice={isTouchDevice}
          group={hoverGroup}
          content={() => <RecentsList onClick={closeAll} className={dropdownStyles} />}
          placement={MdsHoverContentPlacement.Right}
        >
          <MdsIconButton iconKind={MdsIconKind.Clock} />
        </MdsHoverContent>
      </SidebarMainWrapper>
      <SidebarBottomWrapper> </SidebarBottomWrapper>
    </SidebarWrapper>
  );
});

const SidebarWrapper = styled.div(({ theme }) => ({
  color: theme.colors.grey.x700,
  display: `flex`,
  flexDirection: `column`,
  height: `calc(100dvh - ${TOP_BAR_HEIGHT}px)`,
  opacity: 1,
  transition: `opacity 0.25s ease-in-out, transform 0.25s ease-in-out`,
  width: mdsSidebarWidths().collapsed,
  flexShrink: 0,
  zIndex: ZIndex.Sidebar,
  paddingTop: 24,
}));

const SidebarMainWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: mdsSpacings().sm,
  gap: mdsSpacings().md,
});

const SidebarBottomWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  marginTop: "auto",
});

const ComposeIconButton = styled(MdsIconButton)({
  backgroundColor: mdsColors().white,
});

const dropdownStyles = css({
  width: `calc(${mdsSidebarWidths().expanded}px - ${mdsSpacings().md} * 2)`,
});

const activeContentListItemStyles = css({
  backgroundColor: mdsColors().lilac.paler,
});

import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsSpacer } from "@/design-system/components/spacer";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useState } from "react";

export interface MultiSelectActionsProps {
  listState: ListStateObservable;
}

export const MultiSelectActions = observer<MultiSelectActionsProps>(({ listState }) => {
  const [hasDoneLayout, setHasDoneLayout] = useState(false);
  const numberOfSelectedIds = listState.selectedItemIds.length;
  const isOpen = !!numberOfSelectedIds;

  // This works around a bug in Edge Beta 129.
  // Setting this state changes the key on the Wrapper, which forces a re-render.
  // TODO: In October 2024 or later, check the release version of Edge and see if
  // we can remove this workaround.
  // Video walk-through for more details:
  // https://www.loom.com/share/1fc9b04692db4b42a5a629e875d14eec
  useLayoutEffect(() => {
    setHasDoneLayout(true);
  }, []);

  // TODO: consider using 3rd party hooks for all shortcuts
  useEffect(() => {
    const onEscPressed = (event: KeyboardEvent) => {
      if (!listState?.isSelectModeActive) {
        return;
      }

      if (event.key !== "Escape") {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      listState.reset();
    };

    document.addEventListener("keydown", onEscPressed);
    return () => {
      document.removeEventListener("keydown", onEscPressed);
    };
  }, [listState]);

  const manyActions = listState.canAddToCollection && listState.canRemoveFromCollection;

  return (
    <Wrapper isOpen={isOpen} key={`${hasDoneLayout}`}>
      <div className={cx(boxStyle, manyActions)}>
        <Selected>{numberOfSelectedIds} selected</Selected>
        <MdsButton
          label={""}
          className={deselectButtonStyles}
          variant={MdsButtonVariant.Secondary}
          size={MdsButtonSize.Small}
          onClick={listState.clearSelection}
        >
          Deselect
        </MdsButton>
        <MdsSpacer />
        {listState.store.debug.debugModeEnabled && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Copy"
            collapsedIcon={listState.isCopying ? MdsIconKind.Spinner : MdsIconKind.Copy}
            onClick={listState.handleCopySelectedNotes}
            isDisabled={listState.isCopying}
          />
        )}
        {listState.canAddToCollection && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="# Organize"
            collapsedIcon={MdsIconKind.Collection}
            onClick={listState.handleAddToCollection}
          />
        )}
        {listState.canRemoveFromCollection && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Remove from collection"
            collapsedIcon={MdsIconKind.Minus}
            onClick={listState.handleRemoveFromCollection}
          />
        )}
        {listState.canRestoreFromTrash && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Restore from trash"
            collapsedIcon={MdsIconKind.Redo}
            onClick={listState.handleRestore}
          />
        )}
        <MdsPanelResponsiveBtn
          threshold="575px"
          collapsedIcon={MdsIconKind.Trash}
          fullSizeIcon={MdsIconKind.Trash}
          onClick={listState.handleDelete}
          fullSizeLabel={listState.canMoveToTrash ? "Delete" : "Delete"}
        />
      </div>
    </Wrapper>
  );
});

interface WrapperProps {
  isOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>(({ isOpen }) => ({
  opacity: isOpen ? 1 : 0,
  bottom: isOpen ? 10 : -65,
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
  position: "absolute",
  transition: "all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)",
  width: "100%",
  left: 0,
  zIndex: 2,
}));

const boxStyle = css({
  alignItems: "center",
  background: "white",
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: 8,
  boxShadow: `0px 6px 10px rgba(69, 78, 104, 0.2)`,
  display: "flex",
  gap: 8,
  justifyContent: "flex-start",
  padding: `10px 18px`,
  fontSize: 14,
});

const Selected = styled.span(({ theme }) => ({
  color: mdsColors().grey.x500,
  fontWeight: theme.fontWeights.medium,
}));

const deselectButtonStyles = css({
  border: "none",
});

import { ChatHeader } from "@/components/chat/ChatHeader";
import { GuidedChatInstructions } from "@/components/chat/GuidedChatInstructions";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { Panel } from "@/components/layout/Panel";
import { mdsSpacings } from "@/design-system/foundations/typography";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { ChatInput, MentionChip } from "@/pages/chat/ChatInput";
import { ChatMessagesList } from "@/pages/chat/list";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { mentionChipToContext } from "@/pages/chat/lib";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { logger } from "@/modules/logger";

export interface ChatViewProps {
  chatHistory: ChatHistory;
  inSidePanel?: boolean;
  isGuidedChat_experiment?: boolean;
  subscribeToChat: () => void;
}

export const ChatView = observer<ChatViewProps>(function ChatView({
  chatHistory,
  inSidePanel = false,
  isGuidedChat_experiment = false,
  subscribeToChat,
}) {
  const [chatInputHeight, setChatInputHeight] = useState(80);
  const showEmptyState = chatHistory.items.length === 0;

  /**
   * Handling for "normal chat".
   *
   * Once the story is ready, we grab the primary chat conversation and subscribe to it.
   * We unsubscribe on unmount.
   */
  useEffect(() => {
    if (!isGuidedChat_experiment) {
      subscribeToChat();
    }
  }, [subscribeToChat, isGuidedChat_experiment]);

  /**
   * Track views of the chat.
   */
  useEffect(() => {
    /**
     * @todo - Add context about what note/collection is side-by-side with the chat.
     */
    trackEvent(TrackedEvent.ChatView, {
      is_in_side_panel: inSidePanel,
      is_guided_chat: isGuidedChat_experiment,
    });
  }, [inSidePanel, isGuidedChat_experiment]);

  const getDraftMessage = useCallback(() => {
    return chatHistory.draftMessage;
  }, [chatHistory.draftMessage]);

  const handleDraftChange = useCallback(
    (draftMessage?: string) => {
      // if it's an empty message, clear the draft message
      const msg = isEmptyMessage(draftMessage) ? undefined : draftMessage;
      chatHistory.setDraftMessage(msg);
    },
    [chatHistory]
  );

  const handleAddMentionToContexts = useCallback(
    (mention: MentionChip) => {
      const mentionContext = mentionChipToContext(mention);
      if (mentionContext) {
        chatHistory.addContext(mentionContext);
      } else {
        logger.error({
          message: "Failed to convert mention to context",
          info: {
            id: mention.id,
            kind: mention.kind ?? "undefined",
          },
        });
      }
    },
    [chatHistory]
  );

  const handleRemoveMentionFromContexts = useCallback(
    (mention: MentionChip) => {
      console.log("handleRemoveMentionFromContexts", mention);
      const mentionContext = mentionChipToContext(mention);
      if (mentionContext) {
        chatHistory.removeContext(mentionContext.id);
      } else {
        logger.error({
          message: "Failed to convert mention to context",
          info: {
            id: mention.id,
            kind: mention.kind ?? "undefined",
          },
        });
      }
    },
    [chatHistory]
  );

  const handleSubmitChatMessage = useCallback(
    (payload: {
      markdownContent: string;
      isGuidedChat_experiment: boolean;
      agentMode: boolean;
    }) => {
      chatHistory.submitChatMessage(payload);
      if (isGuidedChat_experiment) {
        subscribeToChat();
      }
    },
    [chatHistory, isGuidedChat_experiment, subscribeToChat]
  );

  return (
    <StyledPanel>
      <ChatHeader inSidePanel={inSidePanel} isGuidedChat_experiment={isGuidedChat_experiment} />
      {showEmptyState && isGuidedChat_experiment ? (
        <GuidedChatInstructions />
      ) : (
        <ChatMessagesList
          style={{
            overflowX: "hidden",
            transition: "height 0.1s ease-in-out",
            height: `calc(100dvh - ${chatInputHeight}px - ${TOP_BAR_HEIGHT}px - 100px)`,
            marginLeft: mdsSpacings().md,
            marginRight: mdsSpacings().md,
            width: `calc(100% - 2 * ${mdsSpacings().md})`,
          }}
          chatHistory={chatHistory}
          inSidePanel={inSidePanel}
        />
      )}

      <StyledChatInput
        conversationId={chatHistory.conversationId}
        getAvailableChips={chatHistory.getAvailableChips}
        onHeight={setChatInputHeight}
        onSubmit={handleSubmitChatMessage}
        inSidePanel={inSidePanel}
        isGuidedChat_experiment={isGuidedChat_experiment}
        getDraftMessage={getDraftMessage}
        onDraftChange={handleDraftChange}
        addMentionToContexts={handleAddMentionToContexts}
        removeMentionFromContexts={handleRemoveMentionFromContexts}
      />
    </StyledPanel>
  );
});

const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingBottom: theme.spacing.md,
}));

const StyledChatInput = styled(ChatInput)(({ theme }) => ({
  marginLeft: theme.spacing.md,
  marginRight: theme.spacing.md,
  width: `calc(100% - 2 * ${theme.spacing.md})`,
}));

export function isEmptyMessage(message?: string) {
  if (!message) return true;
  const textContent = new DOMParser().parseFromString(message, "text/html").body.textContent;
  if (!textContent) return true;
  return textContent.trim().length === 0;
}

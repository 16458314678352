export enum NoteEditorMode {
  Editable = "editable",
  ReadOnly = "read-only",
  Static = "static",
}

export type MentionTooltipParams = {
  top: number;
  left: number;
  visible: boolean;
  itemId: string;
};

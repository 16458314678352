import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import debounce from "lodash-es/debounce";

export function SectionWrapper({
  children,
  isProcessing,
}: React.PropsWithChildren<{
  isProcessing: boolean;
}>) {
  const [height, setHeight] = useState<number | "auto">(isProcessing ? 0 : "auto");
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = contentRef.current;
    if (!currentRef) return;

    const handleResize = debounce((entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      if (entry) setHeight(entry.contentRect.height);
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(currentRef);

    return () => {
      handleResize.cancel();
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <OuterWrapper height={height} isProcessing={isProcessing}>
      <div ref={contentRef}>{children}</div>
    </OuterWrapper>
  );
}

const OuterWrapper = styled.div<{
  height: number | "auto";
  isProcessing: boolean;
}>(({ height, isProcessing }) => ({
  height: isProcessing ? height : "auto",
  transition: "height 0.33s ease-out",
  overflow: "visible",
  position: "relative",
}));

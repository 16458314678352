import { useEffect, useState } from "react";
import { useAppStore } from "@/store/utils/hooks";
import { observer } from "mobx-react-lite";
import { useLiveQuery } from "dexie-react-hooks";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";
import { MdsItemList } from "@/design-system/components/item-list";
import { CopilotCardHeader } from "@/components/copilot/CopilotCardHeader";
import { CopilotTitleContainer } from "@/components/copilot/CopilotTitleContainer";
import { CopilotContainerText } from "@/components/copilot/CopilotContainerText";
import { CopilotSeeMoreButton } from "@/components/copilot/CopilotSeeMoreButton";
import { CopilotCardContents } from "@/components/copilot/CopilotCardContents";
import { MdsHorizontalDivider } from "@/design-system/components/divider";

export const CopilotMentionThisNote = observer(function MentionThisNote() {
  const { pageStore } = useAppStore();
  const { copilotView } = pageStore;
  const [showAll, setShowAll] = useState(false);

  const { rows, totalCount } = useMentionByNotes({ showAll });

  useEffect(() => {
    setShowAll(false);
  }, [copilotView.activeItem?.id]);

  // Used to dedupe Copilot notes
  useEffect(() => {
    copilotView.setMentionedByIds(rows.map(row => row.payload.id));
  }, [rows, copilotView]);

  if (totalCount === 0) {
    return null;
  }

  return (
    <>
      <CopilotCardHeader>
        <CopilotTitleContainer>
          <CopilotContainerText>Mention this note</CopilotContainerText>
        </CopilotTitleContainer>
      </CopilotCardHeader>
      <CopilotCardContents>
        <MdsItemList rows={rows} />
        {!showAll && totalCount > MAX_ITEMS_TO_SHOW && (
          <CopilotSeeMoreButton onClick={() => setShowAll(true)}>
            See {totalCount - MAX_ITEMS_TO_SHOW} more
          </CopilotSeeMoreButton>
        )}
      </CopilotCardContents>
      <MdsHorizontalDivider />
    </>
  );
});

const MAX_ITEMS_TO_SHOW = 3;

const useMentionByNotes = ({ showAll }: { showAll: boolean }) => {
  const { store, pageStore } = useAppStore();
  const { copilotView } = pageStore;
  const { activeItem } = copilotView;

  const notesData = useLiveQuery(
    () => {
      if (!activeItem) return [];

      return store.notes.searchForNotesMentionedIn({ noteId: activeItem.id });
    },
    [activeItem],
    [] // default value is []
  );

  const totalCount = notesData?.length ?? 0;
  const rows = notesData
    ?.slice(0, getLimit(showAll))
    .map(noteData =>
      getRowForNoteId({
        store,
        noteId: noteData.model_id,
        dateTime: noteData.modified_at,
        inMainPanel: false,
      })
    )
    .filter(row => !!row);

  return { rows, totalCount };
};

// We might want to paginate more granularly in the future but list not expected to be too large anytime soon
const getLimit = (showAll: boolean) => {
  if (showAll) {
    return 10000;
  }

  return MAX_ITEMS_TO_SHOW;
};

import { ChatView } from "@/components/chat/ChatView";
import { useChatSubscription } from "@/components/chat/use-chat-subscription";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { uuidModule } from "@/modules/uuid";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useParams } from "react-router-dom";

interface GuidedChatConversationPageProps {}

export const GuidedChatConversationPage: FC<GuidedChatConversationPageProps> = observer(() => {
  const { store } = useAppStore();
  const { conversationId } = useParams<{ conversationId?: string }>();
  const conversationUuid = uuidModule.maybeParseValue(conversationId);
  const { subscribeToChat } = useChatSubscription(conversationId);

  // the chat page should not have an open side panel
  useEffectOnMount(() => {
    store.sidePanel.close();
  });

  if (!conversationUuid) {
    // TODO: post-experiment, make a better "not found" page for Chat Conversations
    return <div>Conversation not found</div>;
  }

  return (
    <ChatView
      chatHistory={store.chatMessages.getChatHistoryById_forGuidedChat(conversationUuid)}
      subscribeToChat={subscribeToChat}
      isGuidedChat_experiment
    />
  );
});

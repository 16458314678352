import { Uuid } from "@/domains/global/identifiers";
import { PusherEventData, PusherEventKind } from "@/domains/pusher/constants";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";
import { resolvePrimaryChatConversationSyncModelUuid } from "@/modules/uuid/sync-models/resolvePrimaryChatConversationSyncModelUuid";
import { ChatConversationObservable } from "@/store/chat/ChatConversationObservable";
import { ChatConversationModelData } from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateChatConversationOperation } from "@/store/sync/operations/chat/CreateChatConversationOperation";
import { SyncModelKind, SyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import {
  generateChatConversationLiveSyncUpdatePusherChannelKey,
  generateSyncActionChatConversationScopedPusherChannelKey,
} from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import {
  makeObservable,
  action,
  override,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";
import { Channel } from "pusher-js";

export class AppStoreChatConversationStore extends BaseSyncModelStore<
  ChatConversationObservable,
  ChatConversationModelData
> {
  private chatConversationPusherChannels = new Map<string, Channel>();
  private liveChatConversationPusherChannels = new Map<string, Channel>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatConversation, ...injectedDeps });
    makeObservable<
      AppStoreChatConversationStore,
      | "chatConversationPusherChannels"
      | "liveChatConversationPusherChannels"
      | "handleChatConversationLiveSyncUpdate"
    >(this, {
      getChatConversation: true,
      createNewGuidedConversation: false,
      getAsyncPrimaryChatConversation: true,
      primaryChatConversationId: true,
      chatConversationPusherChannels: observable,
      liveChatConversationPusherChannels: observable,
      createSyncModel: false,
      handleChatConversationLiveSyncUpdate: action,
      processSyncUpdate: override,
      primaryChatConversation: computed,
      generatePrimaryChatConversationIfNeeded: action,
      subscribeToChatConversation: action,
      unsubscribeFromChatConversation: action,
      subscribeToLiveChatConversation: action,
      unsubscribeFromLiveChatConversation: action,
    });

    onBecomeObserved(this, "primaryChatConversationId", () => {
      this.subscribeToChatConversation(this.primaryChatConversationId);
    });
    onBecomeUnobserved(this, "primaryChatConversationId", () => {
      this.unsubscribeFromChatConversation(this.primaryChatConversationId);
    });
  }

  createSyncModel(data: SyncUpdateValue<ChatConversationModelData>): ChatConversationObservable {
    return new ChatConversationObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get primaryChatConversationId() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    return resolvePrimaryChatConversationSyncModelUuid({ spaceAccountId });
  }

  async getAsyncPrimaryChatConversation() {
    return await this.getAsync(this.primaryChatConversationId);
  }

  public async processSyncUpdate(update: SyncUpdate<ChatConversationModelData>) {
    await super.processSyncUpdate(update);
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED")
      this.subscribeToChatConversation(update.value.model_id);
    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED")
      this.unsubscribeFromChatConversation(update.value.model_id);
  }

  get primaryChatConversation(): ChatConversationObservable | undefined {
    return this.getChatConversation(this.primaryChatConversationId);
  }

  public getChatConversation(id: Uuid): ChatConversationObservable | undefined {
    return this.get(id);
  }

  async generatePrimaryChatConversationIfNeeded() {
    const primaryChatConversation = await this.getAsyncPrimaryChatConversation();
    if (primaryChatConversation) {
      return;
    }

    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const primaryChatConversationId = resolvePrimaryChatConversationSyncModelUuid({
      spaceAccountId,
    });

    await new CreateChatConversationOperation({
      store: this.store,
      payload: {
        id: primaryChatConversationId,
        is_primary_chat_conversation: true,
        kind: "GLOBAL",
      },
    }).execute();
  }

  async createNewGuidedConversation() {
    const id = uuidModule.generate();

    await new CreateChatConversationOperation({
      store: this.store,
      payload: { id, is_primary_chat_conversation: false, kind: "GUIDED" },
    }).execute();

    return id;
  }

  // CHAT CONVERSATION SUBSCRIPTION - Always subscribed for chat-conversation scoped sync updates
  public subscribeToChatConversation(chatConversationId: string) {
    try {
      if (this.chatConversationPusherChannels.has(chatConversationId)) return;
      const chatConversationPusherChannelKey =
        generateSyncActionChatConversationScopedPusherChannelKey({
          chatConversationId,
        });
      const channel = this.pusher.subscribe(chatConversationPusherChannelKey);
      console.debug(
        "[SYNC][AppStoreChatConversationStore] Subscribing to chat conversation",
        chatConversationId
      );
      channel.bind(PusherEventKind.SYNC_UPDATE_PUBLISHED, this.store.sync.queryForSyncActions);
      this.chatConversationPusherChannels.set(chatConversationId, channel);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreChatConversationStore] Error subscribing to chat conversation",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  public unsubscribeFromChatConversation(chatConversationId: string) {
    try {
      const channel = this.chatConversationPusherChannels.get(chatConversationId);
      if (channel) {
        console.debug(
          "[SYNC][AppStoreChatConversationStore] Unsubscribing from chat conversation",
          chatConversationId
        );
        this.pusher.unsubscribe(channel.name);
      }
      this.chatConversationPusherChannels.delete(chatConversationId);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreChatConversationStore] Error unsubscribing from chat conversation",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  // LIVE CHAT CONVERSATION SUBSCRIPTION - Subscribed when the chat UI is mounted
  public subscribeToLiveChatConversation(chatConversationId: string) {
    if (this.liveChatConversationPusherChannels.has(chatConversationId)) return;

    const chatConversationPusherChannelKey = generateChatConversationLiveSyncUpdatePusherChannelKey(
      { chatConversationId }
    );
    const liveChannel = this.pusher.subscribe(chatConversationPusherChannelKey);
    this.liveChatConversationPusherChannels.set(chatConversationId, liveChannel);
    liveChannel.bind(
      PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED,
      this.handleChatConversationLiveSyncUpdate
    );
  }

  public unsubscribeFromLiveChatConversation(chatConversationId: string) {
    const liveChannel = this.liveChatConversationPusherChannels.get(chatConversationId);
    if (!liveChannel) return;
    this.pusher.unsubscribe(liveChannel.name);
    this.liveChatConversationPusherChannels.delete(chatConversationId);
  }

  private handleChatConversationLiveSyncUpdate = async ({
    value,
    sync_operation_id,
  }: PusherEventData<PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED>) => {
    await this.store.chatMessages.processLiveSyncUpdate(sync_operation_id, value);
  };
}

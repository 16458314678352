import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { action, computed, observable, makeObservable, reaction, runInAction } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListAsyncCollectionRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { uuidModule } from "@/modules/uuid";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { actions } from "@/actions";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { isEmpty } from "lodash-es";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { groupLens, LensGroup } from "@/modules/timeline";
import { CollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { collectionsLensModule, lensModule } from "@/modules/lenses";
import localDb from "@/domains/local-db";
import { liveQuery, Subscription } from "dexie";
import { Maybe } from "@/domains/common/types";
import { EventContext } from "@/domains/metrics/context";
import {
  CollectionDataTransferObject,
  CollectionSearchReturnType,
  CollectionSearchSortByKind,
} from "@/store/collections/types";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import {
  CollectionListPageFilters,
  CollectionListPageLensKind,
  CollectionsListPageParams,
} from "@/store/pages/CollectionsListPageStore/types";

const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;

export class CollectionsListPageStore extends AppSubStore {
  public params: CollectionsListPageParams;

  public deleteCollectionModal: DeleteCollectionModalStore;
  public shareSheetModal: ShareSheetModalStore;

  public collectionsFilterQuery: string = "";

  public isLoading: boolean;

  private isSubscriptionInitialized: boolean;
  private liveQuerySubscription: Maybe<Subscription> = undefined;
  private subscribedCollections: CollectionDataTransferObject[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: DEFAULT_SORT_BY, lens: DEFAULT_LENS, filters: {} };
    this.deleteCollectionModal = new DeleteCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });

    this.isLoading = true;
    this.isSubscriptionInitialized = false;

    makeObservable<
      this,
      | "generateItemDropdown"
      | "initializeLiveQuery"
      | "subscribedCollections"
      | "mapCollectionToRow"
      | "liveQuerySubscription"
      | "isSubscriptionInitialized"
    >(this, {
      isLoading: true,
      itemRows: true,
      groupedLensItems: true,
      isSubscriptionInitialized: true,
      subscribedCollections: true,
      liveQuerySubscription: true,
      initializeLiveQuery: true,
      deleteCollectionModal: false,
      shareSheetModal: false,
      collectionsFilterQuery: observable,

      generateItemDropdown: false,
      shouldShowEmptyState: computed,
      mapCollectionToRow: false,
      orderedItemIds: computed,

      // FILTERING
      setCollectionsFilterQuery: action,
      shouldApplyCollectionsFilterQuery: computed,
      normalizedCollectionsFilterQuery: computed,

      // LENSES + PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setFilters: action,
      setSortBy: action,
      sortOptions: computed,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,
      toggleContactInCreatedBy: action,
      toggleContactInModifiedBy: action,
      clearCreatedBy: action,
      clearModifiedBy: action,

      // ACTIONS
      handleCreateNewCollection: action,
      resetState: action,
      initialize: action,
    });

    reaction(
      () => this.params,
      params => {
        this.store.memDb.settings.setCollectionsListPageParams(params);
      }
    );

    reaction(
      () => this.isSubscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );
  }

  get shouldShowEmptyState() {
    return !this.isLoading && isEmpty(this.itemRows);
  }

  get groupedLensItems(): LensGroup<CollectionDataTransferObject>[] {
    return groupLens(
      this.subscribedCollections.map(tuple => ({
        dateTime: new Date(tuple.datetime),
        item: tuple,
      }))
    );
  }

  get itemRows(): MdsItemListRowData[] {
    if (this.sortBy === SortByKind.Alphabetical) {
      return this.subscribedCollections.map(tuple => this.mapCollectionToRow(tuple.modelId));
    }

    const output: MdsItemListRowData[] = [];
    for (const group of this.groupedLensItems) {
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      for (const item of group.items) {
        output.push(this.mapCollectionToRow(item.item.modelId));
      }
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get shouldApplyCollectionsFilterQuery() {
    return !isEmpty(this.normalizedCollectionsFilterQuery);
  }

  get normalizedCollectionsFilterQuery() {
    return this.collectionsFilterQuery.trim().toLowerCase();
  }

  get lens() {
    return this.params.lens;
  }

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    const items: MdsDropdownItem[] = [
      {
        id: "sort-by-divider",
        kind: MdsDropdownItemKind.Detail,
        text: "Sort by",
      },
      {
        id: SortByKind.LastModified,
        kind: MdsDropdownItemKind.Button,
        label: lensModule.sortKindLabelMap[SortByKind.LastModified],
        isChecked: this.sortBy === SortByKind.LastModified,
        onClick: this.setSortBy,
      },
      {
        id: SortByKind.LastCreated,
        kind: MdsDropdownItemKind.Button,
        label: lensModule.sortKindLabelMap[SortByKind.LastCreated],
        isChecked: this.sortBy === SortByKind.LastCreated,
        onClick: this.setSortBy,
      },
      {
        id: SortByKind.LastViewed,
        kind: MdsDropdownItemKind.Button,
        label: lensModule.sortKindLabelMap[SortByKind.LastViewed],
        isChecked: this.sortBy === SortByKind.LastViewed,
        onClick: this.setSortBy,
      },
      {
        id: SortByKind.Alphabetical,
        kind: MdsDropdownItemKind.Button,
        label: lensModule.sortKindLabelMap[SortByKind.Alphabetical],
        isChecked: this.sortBy === SortByKind.Alphabetical,
        onClick: this.setSortBy,
      },
    ];

    if (this.lens === LensKind.SharedWithMe) {
      items.push({
        id: SortByKind.LastShared,
        kind: MdsDropdownItemKind.Button,
        label: lensModule.sortKindLabelMap[SortByKind.LastShared],
        isChecked: this.sortBy === SortByKind.LastShared,
        onClick: this.setSortBy,
      });
    }

    return {
      items,
    };
  }

  public setParams = (params: CollectionsListPageParams) => {
    this.params = params;
  };

  public setLens = (lens: CollectionListPageLensKind) => {
    this.setParams({ ...this.params, lens });
  };

  public setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ ...this.params, sortBy: itemId as CollectionSearchSortByKind });
  };

  public setFilters = (filters: CollectionListPageFilters) => {
    this.setParams({ ...this.params, filters });
  };

  public setCollectionsFilterQuery({ query }: { query: string }) {
    this.collectionsFilterQuery = query;
  }

  public handleCreateNewCollection = async () => {
    const collectionId = uuidModule.generate();
    await this.store.collections.createCollection({
      collectionId,
      title: "",
      description: "",
      eventContext: EventContext.CollectionsActions,
    });

    this.store.navigation.goToCollection({ collectionId });
  };

  public resetState = () => {
    this.collectionsFilterQuery = "";
  };

  public toggleContactInCreatedBy = (spaceAccountId: string) => {
    const createdBy = this.params.filters.createdBy || [];
    const newCreatedBy = createdBy.includes(spaceAccountId)
      ? createdBy.filter(id => id !== spaceAccountId)
      : [...createdBy, spaceAccountId];

    this.setFilters({ ...this.params.filters, createdBy: newCreatedBy });
  };

  public clearCreatedBy = () => {
    this.setFilters({ ...this.params.filters, createdBy: [] });
  };

  public toggleContactInModifiedBy = (spaceAccountId: string) => {
    const modifiedBy = this.params.filters.modifiedBy || [];
    const newModifiedBy = modifiedBy.includes(spaceAccountId)
      ? modifiedBy.filter(id => id !== spaceAccountId)
      : [...modifiedBy, spaceAccountId];

    this.setFilters({ ...this.params.filters, modifiedBy: newModifiedBy });
  };

  public clearModifiedBy = () => {
    this.setFilters({ ...this.params.filters, modifiedBy: [] });
  };

  public initialize = async () => {
    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getCollectionsListPageParams(),
      this.store.memDb.settings.getCollectionsListPageParams(),
    ]);
    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setCollectionsListPageParams(localDBParams);
      await localDb.settings.removeCollectionsListPageParams();
    }
    this.setParams({
      sortBy: localDBParams?.sortBy || memDbParams?.sortBy || DEFAULT_SORT_BY,
      lens: localDBParams?.lens || memDbParams?.lens || DEFAULT_LENS,
      filters: localDBParams?.filters || memDbParams?.filters || {},
    });

    this.initializeLiveQuery();
  };

  private initializeLiveQuery() {
    reaction(
      () => ({ params: this.params, filterQuery: this.normalizedCollectionsFilterQuery }),
      ({ params, filterQuery }) => {
        const { sortBy, lens } = params;
        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(() => {
          const isOwnedByMe = lens === LensKind.All ? undefined : lens === LensKind.AddedByMe;
          const results = this.store.collections.search({
            sortBy,
            query: filterQuery,
            filter: { isOwnedByMe, ...this.params.filters },
            returns: CollectionSearchReturnType.DataTransferObject,
          });
          return results;
        }).subscribe({
          next: rows => {
            runInAction(() => {
              this.subscribedCollections = rows;
              this.isSubscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }

  private generateItemDropdown({
    collectionObservable,
  }: {
    collectionObservable: CollectionObservable;
  }): MdsItemDropdown {
    const ownerActions: MdsDropdownItem[] = collectionObservable.isOwnedByMe
      ? [
          {
            id: `delete-${collectionObservable.id}`,
            kind: MdsDropdownItemKind.Button,
            iconKind: MdsIconKind.Trash,
            label: "Delete",
            onClick: () => {
              this.deleteCollectionModal.open({ collectionId: collectionObservable.id });
            },
          },
        ]
      : [];
    return {
      items: [
        {
          id: `copy-link-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            actions.copyCollectionLinkToClipboard({ collectionId: collectionObservable.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `share-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: collectionObservable.id,
              entityKind: ShareSheetEntityKind.Collection,
              eventContext: EventContext.CollectionsRowActions,
            }),
        },
        ...ownerActions,
      ],
    };
  }

  private mapCollectionToRow(collectionId: string): MdsItemListAsyncCollectionRowData {
    return {
      type: MdsItemListRowType.AsyncCollection,
      key: collectionId,
      size: MdsItemListSize.XLarge,
      payload: {
        collectionId,
        itemRow: (collection: CollectionObservable) => ({
          type: MdsItemListRowType.Item,
          key: collection.id,
          size: MdsItemListSize.XLarge,
          payload: {
            id: collection.id,
            kind: MdsItemKind.Collection,
            titleIcon: collection.sharedAt ? { kind: MdsIconKind.Shared } : undefined,
            createPreviewState: () =>
              new ItemPreviewState({
                store: this.store,
                id: collection.id,
                kind: MdsItemKind.Collection,
              }),
            label: collection.label,
            onClick: () => this.store.navigation.goToCollection({ collectionId: collection.id }),
            sharedBy: collection.sharedBy,
            dateLabel: collectionsLensModule.dateLabelMap[this.sortBy](collection),
            dropdown: this.generateItemDropdown({ collectionObservable: collection }),
            action: undefined,
            extraRows: [
              {
                id: "collection-description",
                content: () => {
                  return collection.itemList.sizeData.data ? (
                    <CollectionItemSubtitle
                      sizeData={collection.itemList.sizeData}
                      description={collection.description}
                    />
                  ) : null;
                },
              },
            ],
          },
        }),
      },
    };
  }
}

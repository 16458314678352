import { MdsItemListActionButtonRow } from "@/design-system/components/item-list/rows/MdsItemListActionButtonRow";
import { MdsItemListAsyncCollectionRow } from "@/design-system/components/item-list/rows/MdsItemListAsyncCollectionRow";
import { MdsItemListAsyncNoteRow } from "@/design-system/components/item-list/rows/MdsItemListAsyncNoteRow";
import { MdsItemListCarouselRow } from "@/design-system/components/item-list/rows/MdsItemListCarouselRow";
import { MdsItemListItemRow } from "@/design-system/components/item-list/rows/MdsItemListItemRow";
import { MdsItemListOtherRow } from "@/design-system/components/item-list/rows/MdsItemListOtherRow";
import { MdsItemListSectionHeaderRow } from "@/design-system/components/item-list/rows/MdsItemListSectionHeaderRow";
import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { MdsItemListRowData, MdsItemListRowType } from "@/design-system/components/item-list/types";
import { FC } from "react";

interface MdsItemListRowRendererProps {
  index: number;
  data: MdsItemListRowData;
  className?: string;
}

export const MdsItemListRowRenderer: FC<MdsItemListRowRendererProps> = ({ data, className }) => {
  switch (data.type) {
    case MdsItemListRowType.Skeleton:
      return <MdsItemListSkeletonRow data={data} className={className} />;
    case MdsItemListRowType.Carousel:
      return <MdsItemListCarouselRow data={data} className={className} />;
    case MdsItemListRowType.Item:
      return <MdsItemListItemRow data={data} className={className} />;
    case MdsItemListRowType.SectionHeader:
      return <MdsItemListSectionHeaderRow data={data} className={className} />;
    case MdsItemListRowType.Padding:
      return <div style={{ height: data.payload.height }} className={className} />;
    case MdsItemListRowType.ActionButton:
      return <MdsItemListActionButtonRow data={data} className={className} />;
    case MdsItemListRowType.AsyncNote:
      return <MdsItemListAsyncNoteRow data={data} className={className} />;
    case MdsItemListRowType.AsyncCollection:
      return <MdsItemListAsyncCollectionRow data={data} className={className} />;
    case MdsItemListRowType.Other:
      return <MdsItemListOtherRow data={data} className={className} />;
    default:
      throw new Error("Unknown slice type");
  }
};

import { observer } from "mobx-react-lite";
import React, { FC } from "react";

import MemLogo from "@/assets/legacy-images/mem-logo.png";

import { css, cx } from "@/domains/emotion";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsSpacer } from "@/design-system/components/spacer";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { MdsButtonShape } from "@/design-system/components/button/types";
import { topBarStyles } from "@/components/layout/components/topBarStyles";
import { MdsHoverContentGroup } from "@/design-system/components/hover-content/MdsHoverContentGroup";
import {
  IMdsHoverContentArgs,
  MdsHoverContent,
} from "@/design-system/components/hover-content/MdsHoverContent";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { TrialInfoBadge } from "@/components/trial/badge";
import { ScreenSize } from "@/design-system/foundations";
import { IAsyncData } from "@/domains/async/AsyncData";
import { LoadingAnimator } from "@/design-system/components/animation";
import { MdsSpinner } from "@/design-system/components/loader/MdsSpinner";
import { AccountProfileNavbarCard } from "@/components/layout/components/account-profile/AccountProfileNavbarCard";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { DropdownAnimation } from "@/components/dropdown-animation";

const wrapperStyles = css({
  width: "100%",
  minWidth: "100% !important",
});

const logoStyles = css({
  width: "16px",
  height: "16px",
  userSelect: "none",
  pointerEvents: "none",
  draggable: "false",
});

const menuButtonContainerStyles = css({
  display: "flex",
});

// used for toggle while on chat page
const activeButtonStyles = css({
  backgroundColor: "#F3F3F5 !important",
});

const popoverContainerStyles = css({
  position: "relative",
});

export interface TopBarContentProps {
  screenSize: IAsyncData<ScreenSize>;
  isTouchDevice: boolean;
  isChatPath: boolean;
  isCollectionPath: boolean;
  isSearchPath: boolean;
  isNotesPath: boolean;
  isLoading: boolean;
  hoverGroup: MdsHoverContentGroup | undefined;
  renderRecentNotes: (args: IMdsHoverContentArgs) => React.ReactNode;
  renderRecentCollections: (args: IMdsHoverContentArgs) => React.ReactNode;
  renderFavorites: (args: IMdsHoverContentArgs) => React.ReactNode;
  handleToggleSidebar: () => void;
  handleClickCompose: () => void;
  handleClickNotes: () => void;
  handleClickCollections: () => void;
  handleClickChat: () => void;
  handleClickIntegrations: () => void;
  handleClickSearch: () => void;
  isGuestMode: boolean;
  shouldShowTrialInfoBadge: boolean;
}

const buttonStyles = css({
  borderRadius: 100,
  marginRight: 8,
  whiteSpace: "nowrap",
  minHeight: "unset",
});

export const TopBarContent: FC<TopBarContentProps> = observer(
  ({
    screenSize,
    isTouchDevice,
    isChatPath,
    isCollectionPath,
    isNotesPath,
    isSearchPath,
    isLoading,
    hoverGroup,
    renderRecentNotes,
    renderRecentCollections,
    renderFavorites,
    handleToggleSidebar,
    handleClickCompose,
    handleClickNotes,
    handleClickCollections,
    handleClickChat,
    handleClickSearch,
    isGuestMode,
    shouldShowTrialInfoBadge,
  }) => {
    if (!screenSize.data) return null;

    const isDesktopSized = screenSize.data === ScreenSize.Desktop;
    return (
      <div className={cx(wrapperStyles, topBarStyles)}>
        <div style={{ minWidth: "100% !important" }} className={"app-top-bar light-mode"}>
          {!isDesktopSized && (
            <MdsButton
              className={buttonStyles}
              shape={MdsButtonShape.Round}
              iconKind={MdsIconKind.Menu}
              label={null}
              variant={MdsButtonVariant.TextTertiary}
              onClick={handleToggleSidebar}
            />
          )}
          <MdsHoverContent
            isTouchDevice={isTouchDevice}
            group={hoverGroup}
            content={renderRecentNotes}
          >
            <MdsButton
              label=""
              className={cx(buttonStyles, isNotesPath && activeButtonStyles)}
              variant={MdsButtonVariant.TextTertiary}
              shape={MdsButtonShape.Round}
              onClick={handleClickNotes}
            >
              <img className={logoStyles} src={MemLogo} />
              Home
            </MdsButton>
          </MdsHoverContent>
          {isDesktopSized && (
            <>
              <MdsHoverContent
                isTouchDevice={isTouchDevice}
                group={hoverGroup}
                content={renderRecentCollections}
              >
                <MdsButton
                  className={cx(buttonStyles, isCollectionPath && activeButtonStyles)}
                  shape={MdsButtonShape.Round}
                  iconKind={MdsIconKind.Collection}
                  label="Collections"
                  variant={MdsButtonVariant.TextTertiary}
                  onClick={handleClickCollections}
                />
              </MdsHoverContent>
              <MdsHoverContent group={hoverGroup} content={renderFavorites}>
                <MdsButton
                  className={buttonStyles}
                  shape={MdsButtonShape.Round}
                  iconKind={MdsIconKind.Thumbtack}
                  label="Pins"
                  variant={MdsButtonVariant.TextTertiary}
                />
              </MdsHoverContent>
            </>
          )}
          <div className={menuButtonContainerStyles}>
            <div className={popoverContainerStyles}>
              <MdsButton
                className={cx(buttonStyles, isChatPath && activeButtonStyles)}
                shape={MdsButtonShape.Round}
                iconKind={MdsIconKind.Message}
                label={isDesktopSized ? "Chat" : null}
                variant={MdsButtonVariant.TextTertiary}
                onClick={handleClickChat}
                tooltipConfig={{
                  label: "⌘ + J",
                  placement: MdsTooltipPlacement.Bottom,
                  delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
                }}
              />
            </div>

            <MdsButton
              className={cx(buttonStyles, isSearchPath && activeButtonStyles)}
              shape={MdsButtonShape.Round}
              iconKind={MdsIconKind.Search}
              label={isDesktopSized ? "Search or ask your Mem" : null}
              variant={MdsButtonVariant.TextTertiary}
              onClick={handleClickSearch}
              tooltipConfig={{
                label: "⌘ + K",
                placement: MdsTooltipPlacement.Bottom,
                delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
              }}
            />
          </div>
          <MdsSpacer />
          <TrialInfoBadge visible={shouldShowTrialInfoBadge} />
          <button className="app-top-bar-button global-compose-button" onClick={handleClickCompose}>
            <MdsIcon
              kind={MdsIconKind.ComposeInline}
              innerStyles={{ Icon: { className: css({ color: "#FFFFFF" }) } }}
            />{" "}
            New
            {isDesktopSized ? " note" : ""}
          </button>
          {isGuestMode || !isDesktopSized ? null : (
            <MdsHoverContent
              // requireClick
              contentClassName={userProfileCardPositionStyles}
              content={({ hide }) => (
                <DropdownAnimation innerStyle={{ transformOrigin: "top right" }}>
                  <div className={userProfileCardWrapperStyles}>
                    <div className={userProfileCardSpacerStyles}>
                      <div className={userProfileCardLeftSpacerStyles} />
                      <div className={userProfileCardRightSpacerStyles} />
                    </div>
                    <AccountProfileNavbarCard onHide={hide} />
                  </div>
                </DropdownAnimation>
              )}
            >
              <div className={cx("app-top-bar-button", "user-profile-image")}>
                <LoadingAnimator
                  loading={isLoading}
                  loadingElement={<MdsSpinner size={32} />}
                  readyElement={
                    <AccountProfileImage
                      size={ProfileSize.Large}
                      profile={{
                        kind: ProfileKind.Me,
                      }}
                    />
                  }
                />
              </div>
            </MdsHoverContent>
          )}
        </div>
      </div>
    );
  }
);

const userProfileCardPositionStyles = css({
  top: 35,
  right: 0,
  left: "unset",
});

const userProfileCardWrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const userProfileCardSpacerStyles = css({
  height: 2,
  opacity: 0,
  backgroundColor: "red",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignContent: "flex-start",
  justifyContent: "flex-start",
});

const userProfileCardLeftSpacerStyles = css({
  marginTop: 20,
  height: 20,
  pointerEvents: "none",
  width: "100%",
  opacity: 0,
  backgroundColor: "green",
});

const userProfileCardRightSpacerStyles = css({
  marginTop: -8,
  height: 10,
  width: 54,
  opacity: 0,
  backgroundColor: "blue",
});

import { FormattingToolbar } from "@/components/note/editor/FormattingToolbar";
import { logger } from "@/modules/logger";
import { PublicAppStore } from "@/store";
import { getContactDisplayNames, getNoteMetadata } from "@/store/note/metadata";
import { INoteObservable } from "@/store/note/types";
import {
  ChangeTableOperation,
  ChangeTablePosition,
  ChangeTableTarget,
  MemCommonCloseMentionCondition,
  MemCommonEditorAction,
  MemCommonEditorActionKind,
  MemCommonEditorClickedItemKind,
  MemCommonEditorContext,
  MemCommonEditorEvent,
  MemCommonEditorEventKind,
  MemCommonEditorFileRejectionErrorCode,
  MemCommonEditorImageRejectionErrorCode,
  MemCommonEditorInitializer,
  MemCommonEditorTheme,
  MemCommonMentionKeyDownKind,
  MemCommonMentionKind,
  MemCommonTableMenuKind,
  editorContextModule,
} from "@mem-labs/common-editor";
import { fromUint8Array, toUint8Array } from "js-base64";
import { filter, noop } from "lodash-es";
import {
  IReactionDisposer,
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import * as Y from "yjs";
import { DateTime } from "luxon";
import { IContactModel } from "@/store/contacts/types";
import { MentionChip } from "@/pages/chat/ChatInput";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown/types";
import { css, cx } from "@/domains/emotion";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations/colors";
import { objectModule } from "@/modules/object";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { windowModule } from "@/modules/window";
import { UseFloatingOptions } from "@floating-ui/react";
import { fileModule } from "@/modules/file";
import { MdsFloatingDropdownContentProps } from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { EventContext } from "@/domains/metrics/context";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import {
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
  mdsSpacings,
} from "@/design-system/foundations/typography";
import { MentionTooltipParams } from "@/components/note/types";

export class MemCommonEditorStore {
  private autoFocus = false;
  private dispatchAction: (event: MemCommonEditorAction) => void;
  private chatHistory?: ChatHistory;
  private goToMention?: INotesViewPageStore["goToMention"];
  private openFileUploadRejectedModal?: INotesViewPageStore["openFileUploadRejectedModal"];
  private openImageUploadRejectedModal?: INotesViewPageStore["openImageUploadRejectedModal"];
  private id: string;
  private isNoteEmpty = false;
  private placeholder = "";
  private processedUpdateIds = new Set<string>();
  private publicStore: PublicAppStore;
  private reactionDisposers: IReactionDisposer[] = [];
  private reactionDisposersRegistered = false;
  private readOnly = false;
  private onReadOnlyNoteClicked?: () => void;

  edited = false;
  mentionQuery = "";
  mentionClientRect: DOMRect | undefined = undefined;
  mentionAvailableChips: MentionChip[] | undefined = undefined;
  scrollIntoViewMentionKeyboardSelectionChipIndex = false;
  mentionKeyboardSelectionChipIndex = 1;
  mouseY = 0;
  lockedMouseY = 0;
  tableMenuClientRect: DOMRect | undefined = undefined;
  tableMenuPlacement: UseFloatingOptions["placement"] = "top-start";
  tableMenuOffsetOptions?: MdsFloatingDropdownContentProps["offsetOptions"];
  tableMenuContentList: MdsDropdownContentList = { items: [] };
  noteObservable?: INoteObservable;
  myAccount?: IContactModel;
  /** @description Force a common-editor / Tiptap re-rendering to remove discarded edits. */
  reloadRequired = false;
  hasContentError = false;
  hasFatalError = false;
  getAvailableChipsCalls = 0;
  mentionTooltipParams: MentionTooltipParams = {
    top: 0,
    left: 0,
    visible: false,
    itemId: "",
  };

  constructor({
    autoFocus,
    dispatchAction,
    goToMention,
    openFileUploadRejectedModal,
    openImageUploadRejectedModal,
    noteObservable,
    myAccount,
    placeholder = "Add to your Mem",
    publicStore,
    readOnly,
    chatHistory,
    onReadOnlyNoteClicked,
  }: {
    autoFocus: boolean;
    getAvailableChips?: ChatHistory["getAvailableChips"];
    goToMention?: INotesViewPageStore["goToMention"];
    openFileUploadRejectedModal?: INotesViewPageStore["openFileUploadRejectedModal"];
    openImageUploadRejectedModal?: INotesViewPageStore["openImageUploadRejectedModal"];
    dispatchAction: (event: MemCommonEditorAction) => void;
    noteObservable: INoteObservable;
    myAccount?: IContactModel;
    placeholder?: string;
    publicStore: PublicAppStore;
    readOnly: boolean;
    chatHistory?: ChatHistory;
    onReadOnlyNoteClicked?: () => void;
  }) {
    this.autoFocus = !!autoFocus;
    this.dispatchAction = dispatchAction;
    this.chatHistory = chatHistory;
    this.goToMention = goToMention;
    this.openFileUploadRejectedModal = openFileUploadRejectedModal;
    this.openImageUploadRejectedModal = openImageUploadRejectedModal;
    this.id = noteObservable.id;
    this.placeholder = placeholder;
    this.publicStore = publicStore;
    this.readOnly = readOnly;
    this.onReadOnlyNoteClicked = onReadOnlyNoteClicked;

    logger.debug({
      message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Create`,
    });

    this.noteObservable = noteObservable;
    this.myAccount = myAccount;

    makeObservable<
      this,
      | "autoFocus"
      | "dispatchAction"
      | "getAvailableChips"
      | "goToMention"
      | "openFileUploadRejectedModal"
      | "openImageUploadRejectedModal"
      | "id"
      | "isNoteEmpty"
      | "placeholder"
      | "processedUpdateIds"
      | "publicStore"
      | "reactionDisposers"
      | "reactionDisposersRegistered"
      | "readOnly"
      | "outdatedClientMessageModalDefinition"
      | "chatHistory"
      | "onReadOnlyNoteClicked"
      | "setMentionTooltipParams"
    >(this, {
      isInsertMenuActive: false,
      memoryQueue: false,
      chatHistory: false,
      autoFocus: false,
      dispatchAction: false,
      getAvailableChips: false,
      goToMention: false,
      openFileUploadRejectedModal: false,
      openImageUploadRejectedModal: false,
      id: false,
      isNoteEmpty: false,
      placeholder: false,
      processedUpdateIds: false,
      publicStore: false,
      reactionDisposers: false,
      reactionDisposersRegistered: false,
      readOnly: false,
      onReadOnlyNoteClicked: false,
      outdatedClientMessageModalDefinition: false,

      edited: observable,
      mentionQuery: observable,
      mentionClientRect: observable,
      mentionAvailableChips: observable,
      mentionKeyboardSelectionChipIndex: observable,
      scrollIntoViewMentionKeyboardSelectionChipIndex: observable,
      mouseY: observable,
      lockedMouseY: observable,
      tableMenuContentList: observable,
      tableMenuClientRect: observable,
      tableMenuPlacement: observable,
      tableMenuOffsetOptions: observable,
      noteObservable: observable,
      myAccount: observable,
      reloadRequired: observable,
      hasContentError: observable,
      hasFatalError: observable,

      registerReactions: false,
      mount: false,
      unmount: false,
      getAvailableChipsCalls: false,

      setMentionQuery: action,
      setMentionClientRect: action,
      setMentionAvailableChips: action,
      setMentionKeyboardSelectedChipIndex: action,
      setMouseY: action,
      setTableMenuClientRect: action,
      setTableMenuPlacement: action,
      setTableMenuOffsetOptions: action,
      setTableMenuContentList: action,
      setNote: action,
      setReloadRequired: action,
      setHasContentError: action,
      setHasFatalError: action,
      handleDropdownHover: action,
      handleSelectMention: action,
      queuedUpdates: computed,
      editorInitializer: computed,
      editorEventHandler: computed,
      mentionContentList: computed,
      authorNames: computed,
      metadata: computed,
      metadataTooltip: computed,
      isTableMenuOpen: computed,
      mentionTooltipParams: observable,
      setMentionTooltipParams: action,
      resetMentionTooltipParams: action,
    });
  }

  registerReactions() {
    if (this.reactionDisposersRegistered) {
      return;
    }

    this.reactionDisposers.push(
      reaction(
        () => this.reloadRequired,
        reload => {
          if (!reload) return;

          setTimeout(() => {
            // After unmounting editor.
            this.setReloadRequired(false);
          });
        }
      ),
      reaction(
        () => ({
          canWrite: this.noteObservable?.canWrite,
          isTrashed: this.noteObservable?.isTrashed,
        }),
        ({ isTrashed, canWrite }) => {
          if (this.readOnly) return;

          const readOnly = (() => {
            if (isTrashed) return true;

            if (typeof canWrite === "boolean") return !canWrite;
          })();

          if (typeof readOnly !== "boolean") return;

          this.dispatchAction({
            kind: MemCommonEditorActionKind.SetReadOnly,
            payload: { readOnly },
          });
        }
      ),
      reaction(
        () => this.noteObservable?.remoteContent,
        encodedContentUpdate => {
          if (!encodedContentUpdate) return;

          logger.debug({
            message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Remote update received, ${encodedContentUpdate.length} bytes`,
          });
          this.dispatchAction({
            kind: MemCommonEditorActionKind.ApplyRemoteUpdate,
            payload: {
              encodedContentUpdate,
            },
          });
        }
      ),
      reaction(
        () => this.queuedUpdates,
        encodedContentUpdates => {
          if (!encodedContentUpdates) return;

          const filteredUpdates = filter(
            encodedContentUpdates,
            e => !this.processedUpdateIds.has(e.id) && e.encodedContentDiff.length > 0
          );
          const filteredUpdateIds = filteredUpdates.map(e => e.id);
          const updates = filteredUpdates.map(e => toUint8Array(e.encodedContentDiff));

          if (updates.length === 0) return;

          try {
            const update = Y.mergeUpdates(updates);
            const encodedContentUpdate = fromUint8Array(update);

            logger.debug({
              message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Local updates, ${encodedContentUpdate.length} bytes`,
              info: {
                encodedContentUpdateIds: encodedContentUpdates.map(e => e.id),
                processedUpdateIds: [...this.processedUpdateIds],
                filteredUpdateIds,
              },
            });

            this.dispatchAction({
              kind: MemCommonEditorActionKind.ApplyRemoteUpdate,
              payload: { encodedContentUpdate },
            });

            runInAction(() => {
              this.processedUpdateIds = new Set([...this.processedUpdateIds, ...filteredUpdateIds]);
            });
          } catch (error) {
            logger.error({
              message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Failed to apply remote update`,
              info: {
                error: objectModule.safeErrorAsJson(error as Error),
                filteredUpdates,
              },
            });
          }
        }
      ),
      reaction(
        () => {
          return {
            condition: this.mentionContentList.items.length
              ? MemCommonCloseMentionCondition.RenderingResults
              : MemCommonCloseMentionCondition.NoResults,
            send: this.mentionQuery.length > 0,
          };
        },
        ({ condition, send }) => {
          if (!send) return;

          this.dispatchAction({
            kind: MemCommonEditorActionKind.CloseMention,
            payload: { condition },
          });
        }
      ),
      reaction(() => {
        return this.chatHistory?.searchSuggestions;
      }, noop)
    );
  }

  mount(): void {
    this.registerReactions();
    this.publicStore.interface.addReloadRequiredListener(this.id, this.setReloadRequired);
  }

  async unmount(): Promise<void> {
    this.publicStore.interface.removeReloadRequiredListener(this.id, this.setReloadRequired);

    logger.debug({
      message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Shutting down ${this.readOnly ? "read-only" : "writable"} note editor`,
    });

    this.dispatchAction({
      kind: MemCommonEditorActionKind.Shutdown,
      payload: null,
    });

    // Delay dispose so queued updates are sent and processed.
    logger.debug({
      message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Disposing ${this.reactionDisposers.length} reactions`,
    });

    this.reactionDisposers.forEach(dispose => dispose());

    await this.memoryQueue?.waitForQueue();
    await this.noteObservable?.clearFakeSyncUpdates();

    if (!this.readOnly && this.isNoteEmpty) {
      // If the user reopens and enters something, we shouldn't do this.
      await this.noteObservable?.deleteEmptyNote();
    }

    this.setNote(undefined);
  }

  get memoryQueue() {
    return this.noteObservable?.memoryQueue;
  }

  get queuedUpdates() {
    return [
      ...(this.noteObservable?.queuedDocumentUpdates ?? []),
      ...(this.memoryQueue?.operations
        .filter(
          ({ operation: { operationKind } }) => operationKind === "UPDATE_NOTE_CONTENT_USING_DIFF"
        )
        .map(e => ({
          id: e.operation.id,
          encodedContentDiff: (e.operation as UpdateNoteContentUsingDiffOperation).payload
            .encoded_content_diff,
        })) ?? []),
    ];
  }

  // ACTIONS
  setMentionQuery(mentionQuery: string) {
    this.mentionQuery = mentionQuery;
  }

  setMentionClientRect(mentionClientRect?: DOMRect) {
    this.mentionClientRect = mentionClientRect;
  }

  setMentionAvailableChips(mentionAvailableChips?: MentionChip[]) {
    this.mentionAvailableChips = mentionAvailableChips;
    this.setMentionKeyboardSelectedChipIndex(mentionAvailableChips?.length ? 0 : -1);
  }

  setMentionKeyboardSelectedChipIndex(mentionKeyboardSelectionChipIndex: number) {
    // Lock hover to select until mouse is moved.
    this.lockedMouseY = this.mouseY;
    this.mentionKeyboardSelectionChipIndex = mentionKeyboardSelectionChipIndex;
    this.scrollIntoViewMentionKeyboardSelectionChipIndex = true;
  }

  setMouseY(mouseY: number) {
    this.mouseY = mouseY;
    if (Math.abs(this.lockedMouseY - mouseY) > 2) {
      // Mouse was moved, unlock hover to select.
      this.lockedMouseY = 0;
    }
  }

  setTableMenuClientRect(clientRect?: DOMRect) {
    this.tableMenuClientRect = clientRect;
  }

  setTableMenuPlacement(placement: UseFloatingOptions["placement"]) {
    this.tableMenuPlacement = placement;
  }

  setTableMenuOffsetOptions(offsetOptions?: MdsFloatingDropdownContentProps["offsetOptions"]) {
    this.tableMenuOffsetOptions = offsetOptions;
  }

  setTableMenuContentList(contentList: MdsDropdownContentList) {
    this.tableMenuContentList = contentList;
  }

  setNote(noteObservable?: INoteObservable) {
    if (this.noteObservable !== noteObservable) {
      this.noteObservable = noteObservable;
    }
  }

  setReloadRequired = (reload = true) => {
    logger.debug({ message: `[${this.id}] MemCommonEditorStore: reload=${reload}` });
    this.reloadRequired = reload;
  };

  setHasContentError = () => {
    this.hasContentError = true;
  };

  setHasFatalError = () => {
    this.hasFatalError = true;
  };

  handleDropdownHover = ({ itemId }: { itemId?: string }) => {
    // Lock hover to select until mouse is moved enough.
    // Since it's assigned to mouseenter/leave, this only takes effect when a new element is hovered.
    if (this.lockedMouseY) return;

    this.mentionKeyboardSelectionChipIndex =
      this.mentionAvailableChips?.findIndex(e => e.id === itemId) ?? -1;
    this.scrollIntoViewMentionKeyboardSelectionChipIndex = false;
  };

  handleSelectMention = async (option?: MentionChip) => {
    if (!this.mentionAvailableChips) return;

    const mention = (() => {
      if (option) return option;

      return this.mentionAvailableChips[Math.max(this.mentionKeyboardSelectionChipIndex, 0)];
    })();

    if (!mention) return;

    const action = typeof mention.action === "function" ? await mention.action() : mention.action;
    if (!action && typeof mention.action === "function") return;

    await mention.beforeSelection?.();

    if (this.mentionQuery.startsWith("#")) {
      this.noteObservable?.collectionList?.addCollection({
        collectionId: mention.id,
        triggerSuccessToast: false,
        eventContext: EventContext.EditorInline,
        wait: false,
      });
    }

    this.dispatchAction({
      kind: MemCommonEditorActionKind.CloseMention,
      payload: {
        id: mention.id,
        kind: this.mentionQuery.startsWith("#")
          ? MemCommonMentionKind.Collection
          : // if it starts w/ "/" it's safe to return Note here because no mention is inserted.
            MemCommonMentionKind.Note,
        label: mention.label,
        action,
      },
    });
  };

  resetMentionTooltipParams = () => {
    this.mentionTooltipParams = {
      top: 0,
      left: 0,
      visible: false,
      itemId: "",
    };
  };

  private setMentionTooltipParams = (target: EventTarget | null, itemId: string) => {
    if (!target || !(target instanceof HTMLElement)) {
      return this.resetMentionTooltipParams();
    }

    const element = target as HTMLElement;
    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    this.mentionTooltipParams = {
      top: rect.top + scrollTop - 6, // Position above with small offset
      left: rect.left + rect.width / 2 + scrollLeft, // Center horizontally
      visible: true,
      itemId,
    };
  };

  // COMPUTED

  get editorInitializer(): MemCommonEditorInitializer {
    this.processedUpdateIds = new Set(this.noteObservable?.queuedDocumentUpdates.map(e => e.id));

    const encodedContent = this.noteObservable?.remoteContent ?? undefined;
    const encodedDocumentUpdates = this.queuedUpdates
      .map(e => e.encodedContentDiff)
      .filter(s => s.length > 0);

    logger.debug({
      message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Initialize ${this.readOnly ? "read-only" : "writable"} note editor with ${encodedContent?.length} bytes, updates with ${encodedDocumentUpdates?.map(s => s.length).join(", ")} bytes`,
      info: { processedUpdateIds: Array.from(this.processedUpdateIds) },
    });

    const defaultEditorContext = editorContextModule.generateDefaultEditorContext();

    const context: MemCommonEditorContext =
      this.noteObservable?.generateCommonEditorContext() ?? defaultEditorContext;

    return async () => ({
      encodedContent,
      encodedDocumentUpdates,
      toolbar: !this.readOnly,
      readOnly: this.readOnly || !!this.noteObservable?.isTrashed || !this.noteObservable?.canWrite,
      autoFocus: this.autoFocus ?? this.publicStore?.interface.isHoverable,
      noteId: this.id,
      enableWebSockets: false,
      maxUpdateThrottleMillis: 2000,
      placeholder: this.placeholder,
      Toolbar: FormattingToolbar,
      metadata: undefined,
      theme: MemCommonEditorTheme.Light,
      webSocketAuthToken: undefined,
      context,
    });
  }

  get editorEventHandler() {
    return (event: MemCommonEditorEvent) => {
      logger.debug({
        message: "[MemCommonEditorStore] Event received",
        info: { event: objectModule.safeAsJson(event) },
      });

      switch (event.kind) {
        case MemCommonEditorEventKind.ReadOnlyNoteClicked: {
          this.onReadOnlyNoteClicked?.();
          break;
        }
        case MemCommonEditorEventKind.ItemOver: {
          if (event.payload.kind === MemCommonEditorClickedItemKind.Mention) {
            this.setMentionTooltipParams(event.payload.eventTarget, event.payload.id);
          }
          break;
        }
        case MemCommonEditorEventKind.ItemOut: {
          this.setMentionTooltipParams(null, event.payload.id);
          break;
        }
        case MemCommonEditorEventKind.ItemClicked: {
          switch (event.payload.kind) {
            case MemCommonEditorClickedItemKind.Task:
              // Nothing special on web.
              break;
            case MemCommonEditorClickedItemKind.Hyperlink:
              // Link is openened from toolbar.
              break;
            case MemCommonEditorClickedItemKind.Mention: {
              const { url, mentionKind, id, keys } = event.payload;
              if (this.goToMention) {
                this.goToMention({ url, mentionKind, id, keys });
              } else {
                windowModule.openInNewTab({ url: new URL(url) });
              }
              break;
            }
          }
          break;
        }

        case MemCommonEditorEventKind.TableMenu: {
          this.setTableMenuClientRect(event.payload.clientRect ?? undefined);
          switch (event.payload.kind) {
            case MemCommonTableMenuKind.None: {
              this.setTableMenuPlacement("bottom-start");
              this.setTableMenuOffsetOptions();
              this.setTableMenuContentList({
                items: [],
              });
              break;
            }
            case MemCommonTableMenuKind.Column: {
              this.setTableMenuPlacement("top");
              this.setTableMenuOffsetOptions([16]);
              this.setTableMenuContentList({
                items: [
                  {
                    id: "add-column-before",
                    kind: MdsDropdownItemKind.Button,
                    label: "Add column before",
                    iconKind: MdsIconKind.ArrowLeftToLine,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Insert,
                          position: ChangeTablePosition.Before,
                          target: ChangeTableTarget.Column,
                        },
                      }),
                  },
                  {
                    id: "add-column-after",
                    kind: MdsDropdownItemKind.Button,
                    label: "Add column after",
                    iconKind: MdsIconKind.ArrowRightToLine,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Insert,
                          position: ChangeTablePosition.After,
                          target: ChangeTableTarget.Column,
                        },
                      }),
                  },
                  {
                    id: "delete-column",
                    kind: MdsDropdownItemKind.Button,
                    label: "Delete column",
                    iconKind: MdsIconKind.Trash,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Delete,
                          target: ChangeTableTarget.Column,
                        },
                      }),
                  },
                ],
              });
              break;
            }
            case MemCommonTableMenuKind.Row: {
              this.setTableMenuPlacement("left");
              this.setTableMenuOffsetOptions([4]);
              this.setTableMenuContentList({
                items: [
                  {
                    id: "add-row-before",
                    kind: MdsDropdownItemKind.Button,
                    label: "Add row above",
                    iconKind: MdsIconKind.ArrowUpToLine,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Insert,
                          position: ChangeTablePosition.Before,
                          target: ChangeTableTarget.Row,
                        },
                      }),
                  },
                  {
                    id: "add-row-after",
                    kind: MdsDropdownItemKind.Button,
                    label: "Add row below",
                    iconKind: MdsIconKind.ArrowDownToLine,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Insert,
                          position: ChangeTablePosition.After,
                          target: ChangeTableTarget.Row,
                        },
                      }),
                  },
                  {
                    id: "delete-row",
                    kind: MdsDropdownItemKind.Button,
                    label: "Delete row",
                    iconKind: MdsIconKind.Trash,
                    onClick: () =>
                      this.dispatchAction({
                        kind: MemCommonEditorActionKind.ChangeTable,
                        payload: {
                          operation: ChangeTableOperation.Delete,
                          target: ChangeTableTarget.Row,
                        },
                      }),
                  },
                ],
              });
              break;
            }
          }
          break;
        }

        case MemCommonEditorEventKind.MentionUpdateQuery: {
          const { clientRect, mentionQuery } = event.payload;
          this.setMentionClientRect(clientRect ?? undefined);
          this.setMentionQuery(mentionQuery);
          // Track call count to handle race conditions
          const currentCall = ++this.getAvailableChipsCalls;

          if (!mentionQuery || !this.chatHistory?.getAvailableChips) {
            // TODO: any pending getAvailableChips should be cancelled / ignored.
            this.setMentionAvailableChips(undefined);
            break;
          }

          this.chatHistory
            ?.getAvailableChips(mentionQuery, {
              inlineCreation: true,
              excludeCurrent: false,
              excludeId: this.id,
            })
            .then(availableChips => {
              if (currentCall !== this.getAvailableChipsCalls) return;
              this.setMentionAvailableChips(availableChips ?? []);
            })
            .catch(() => {
              if (currentCall !== this.getAvailableChipsCalls) return;
              this.setMentionAvailableChips(undefined);
            });

          break;
        }

        case MemCommonEditorEventKind.MentionKeyDown: {
          if (!this.mentionAvailableChips) return;

          const index = this.mentionKeyboardSelectionChipIndex;
          switch (event.payload.kind) {
            case MemCommonMentionKeyDownKind.ArrowDown: {
              this.setMentionKeyboardSelectedChipIndex(
                index < 0
                  ? 0
                  : (index + 1 + this.mentionAvailableChips.length) %
                      this.mentionAvailableChips.length
              );
              break;
            }
            case MemCommonMentionKeyDownKind.ArrowUp: {
              this.setMentionKeyboardSelectedChipIndex(
                index < 0
                  ? this.mentionAvailableChips.length - 1
                  : (index - 1 + this.mentionAvailableChips.length) %
                      this.mentionAvailableChips.length
              );
              break;
            }
            case MemCommonMentionKeyDownKind.Enter: {
              this.handleSelectMention();
              break;
            }
          }
          break;
        }

        case MemCommonEditorEventKind.FileDownloaded: {
          fileModule.downloadFileFromUrl({
            fileName: event.payload.fileName,
            downloadUrl: event.payload.downloadUrl,
          });
          break;
        }

        case MemCommonEditorEventKind.FileOpened: {
          fileModule.downloadFileFromUrlInNewTab({
            fileName: event.payload.fileName,
            downloadUrl: event.payload.downloadUrl,
          });
          break;
        }

        case MemCommonEditorEventKind.ImageAdded: {
          this.noteObservable?.uploadImageAssociatedWithNote({
            info: event.payload.info,
          });
          break;
        }

        case MemCommonEditorEventKind.ImageRejected: {
          switch (event.payload.errorCode) {
            case MemCommonEditorImageRejectionErrorCode.ImageTooLarge: {
              this.openImageUploadRejectedModal?.({
                imageName: event.payload.imageName,
                code: event.payload.errorCode,
              });

              break;
            }

            default: {
              this.openImageUploadRejectedModal?.({
                imageName: event.payload.imageName,
                code: MemCommonEditorImageRejectionErrorCode.Unknown,
              });
              break;
            }
          }

          break;
        }

        case MemCommonEditorEventKind.FileAdded: {
          this.noteObservable?.uploadFileAssociatedWithNote({
            info: event.payload.info,
          });
          break;
        }

        case MemCommonEditorEventKind.FileRejected: {
          switch (event.payload.errorCode) {
            case MemCommonEditorFileRejectionErrorCode.FileTooLarge: {
              this.openFileUploadRejectedModal?.({
                fileName: event.payload.fileName,
                code: event.payload.errorCode,
              });

              break;
            }

            default: {
              this.openFileUploadRejectedModal?.({
                fileName: event.payload.fileName,
                code: MemCommonEditorFileRejectionErrorCode.Unknown,
              });
              break;
            }
          }

          break;
        }

        case MemCommonEditorEventKind.IsEmpty: {
          logger.debug({
            message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Note is${event.payload.isEmpty ? "" : " not"} empty`,
          });
          this.isNoteEmpty = event.payload.isEmpty;
          break;
        }
        case MemCommonEditorEventKind.HasBeenEdited: {
          logger.debug({
            message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) Note has been edited`,
          });
          runInAction(() => {
            this.edited = event.payload.edited;
          });
          break;
        }
        case MemCommonEditorEventKind.DocumentMetadataChanged: {
          logger.debug({
            message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) ${this.readOnly ? "Read-only" : "Writable"} note title changed`,
          });
          if (this.readOnly) break;

          // Reusing implementation, empty diff is ignored later.

          this.noteObservable?.updateContentUsingDiff({
            encodedContentDiff: null,
            primaryLabel: event.payload.primaryLabel ?? "",
            secondaryLabel: event.payload.secondaryLabel ?? "",
            mediaKinds: undefined,
            mentions: undefined,
          });
          break;
        }
        case MemCommonEditorEventKind.DocumentChanged: {
          logger.debug({
            message: `[${this.id}] MemCommonEditorStore: (${new Date().toISOString()}) ${this.readOnly ? "Read-only" : "Writable"} note changed, ${event.payload.encodedContentDiff.length} bytes`,
          });
          if (this.readOnly) break;

          const { noteObservable } = this;
          if (!noteObservable) {
            logger.error({ message: `[${this.id}] MemCommonEditorStore: note instance is gone` });
            return;
          }
          noteObservable.updateContentUsingDiff({
            encodedContentDiff: event.payload.encodedContentDiff,
            primaryLabel: event.payload.primaryLabel ?? "",
            secondaryLabel: event.payload.secondaryLabel ?? "",
            mediaKinds: event.payload.mediaKinds,
            mentions: event.payload.mentions,
          });

          break;
        }
        case MemCommonEditorEventKind.ContentError: {
          logger.error({
            message: `[${this.id}] MemCommonEditorStore: content error ${event.payload.message}`,
          });
          this.setHasContentError();
          break;
        }
        case MemCommonEditorEventKind.FatalError: {
          logger.error({
            message: `[${this.id}] MemCommonEditorStore: fatal error ${event.payload.code}`,
          });
          this.setHasFatalError();
          break;
        }
      }
    };
  }

  get mentionContentList() {
    if (!this.mentionAvailableChips) return { items: [] };

    const scrollableItems: MdsDropdownItem[] = [];
    const scrollable: MdsDropdownItem = {
      id: "scrollable",
      kind: MdsDropdownItemKind.ScrollableSection,
      items: scrollableItems,
      className: scrollableMentionStyles,
    };
    const contentList: MdsDropdownContentList = {
      items: [],
    };
    let lastGroupTitle = "";
    this.mentionAvailableChips.forEach((mention, index) => {
      let items = scrollableItems;
      if (mention.alwaysVisible && scrollableItems.length) {
        items = contentList.items;
        items.push({
          id: mention.id + "-separator",
          kind: MdsDropdownItemKind.Divider,
        });
      }
      const { groupTitle } = mention;
      if (groupTitle && groupTitle !== lastGroupTitle) {
        items.push({
          id: `group-${groupTitle}`,
          kind: MdsDropdownItemKind.Detail,
          text: groupTitle,
          className: groupTitleStyles,
        });
        lastGroupTitle = groupTitle;
      }
      items.push({
        id: mention.id,
        kind: MdsDropdownItemKind.Button,
        iconKind: mention.iconKind,
        content: mention.content,
        label: mention.label,
        scrollIntoView:
          this.scrollIntoViewMentionKeyboardSelectionChipIndex &&
          index === this.mentionKeyboardSelectionChipIndex,
        className: cx(
          mention.className,
          index === this.mentionKeyboardSelectionChipIndex ? selectedOptionStyles : noHoverStyles
        ),
        onClick: async () => {
          await this.handleSelectMention(mention);
        },
      });
    });

    const mentionContent = this.mentionQuery.slice(1).trim().toLowerCase();

    if (scrollableItems.length) {
      if (!this.mentionQuery.startsWith("/")) {
        const taggingCollection = this.mentionQuery.startsWith("#");
        const mentionTypeName = taggingCollection ? "collection" : "note";
        const text = `${taggingCollection ? "Tag" : "Mention"} a${!mentionContent.length ? " recent" : ""} ${mentionTypeName}`;

        scrollableItems.unshift({
          id: "header",
          kind: MdsDropdownItemKind.Detail,
          className: detailPaddingStyles,
          text,
        });
      }
      contentList.items.unshift(scrollable);
    }
    return contentList;
  }

  get isInsertMenuActive() {
    return this.mentionQuery.startsWith("/");
  }

  get authorNames() {
    if (!this.noteObservable) return;

    const contacts = Array.from(this.noteObservable.authors);

    if (this.edited && this.myAccount && !contacts.some(e => e.id === this.myAccount?.id)) {
      contacts.push(this.myAccount);
    }

    return getContactDisplayNames({ contacts });
  }

  get metadata() {
    if (!this.noteObservable?.canAccess) return "";

    return getNoteMetadata({ note: this.noteObservable, authorNames: this.authorNames });
  }

  get metadataTooltip() {
    if (!this.noteObservable) return "";

    const modifiedDateTime = DateTime.fromISO(this.noteObservable.modifiedAt);

    const lastEditDate = modifiedDateTime.toFormat("MMM d, yyyy");
    const lastEditTime = modifiedDateTime.toFormat("h:mm a");

    const { authorNames } = this;
    return `Last edited ${lastEditDate} at ${lastEditTime} ${
      authorNames ? ` by ${authorNames}` : ""
    }`;
  }

  get isTableMenuOpen() {
    return this.tableMenuContentList.items.length > 1;
  }
}

const selectedOptionStyles = css({
  background: mdsColors().grey.x25,
});

const noHoverStyles = css({
  "&:hover": {
    background: "unset",
  },
});

const scrollableMentionStyles = css({
  maxHeight: 220,
});

const detailPaddingStyles = css({
  padding: "8px 0 4px 10px",
});

const groupTitleStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
  padding: `${mdsSpacings().sm} ${mdsSpacings().sm} ${mdsSpacings().xs} ${mdsSpacings().sm} `,
});

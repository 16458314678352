import { computed, makeObservable, observable, reaction, action, runInAction } from "mobx";
import { INoteObservable, NotesSortByKind } from "@/store/note";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsItem,
  MdsItemKind,
  MdsItemListCarouselRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSectionHeaderRowData,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { SearchEngineResponse } from "@/store/pages/SearchPageStore/types";
import { ApiSchema, api } from "@/modules/api";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { resolveSavedSearchSyncModelUuid } from "@/modules/uuid/sync-models/resolveSavedSearchSyncModelUuid";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { getMdsCarouselItemForCollection } from "@/design-system/components/item-list/rows/getMdsCarouselItemForCollection";
import { RemoveSavedSearchFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveSavedSearchFromFavoritesOperation";
import { AddSavedSearchToFavoritesOperation } from "@/store/sync/operations/favorites/AddSavedSearchToFavoritesOperation";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { SearchResultVotingButtons } from "@/store/pages/SearchPageStore/searchResultVotingButtons";
import { PaginatableApiQueryObservable } from "@/store/queries/common/PaginatableApiQueryObservable";
import { SearchEngineResult } from "@/modules/url-params/search-engine-params/types";
import {
  createSearchEngineApiQueryObservable,
  SearchEngineKind,
} from "@/store/queries/search/createSearchEngineApiQueryObservable";
import SearchResultDebugInfo from "@/store/pages/SearchPageStore/searchResultDebugInfo";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { SearchPageFilter } from "@/modules/filters/search/SearchPageFilter";
import { generateShortDateString } from "@/domains/date/date";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { mdsColors } from "@/design-system/foundations/colors";
import { css } from "@/domains/emotion";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { searchCollections } from "@/store/collections/CollectionSearch";
import { CollectionSearchReturnType, CollectionSearchSortByKind } from "@/store/collections/types";
import { SearchPagePaginationCapacityReached } from "@/pages/search/components/SearchPagePaginationCapacityReached";

export interface ISearchPageStore {
  isSuccess: boolean;
  isLoading: boolean;
  hasNoItems: boolean;
  itemRows: MdsItemListRowData[];
  filters: SearchPageFilter;
  listState: {
    useListStateEffects: () => void;
    selectedItemIds: string[];
  };
  deepSearchResults: SearchEngineResult[];
  showDeepSearchResults: boolean;
  addToCollectionModal: { selectedNoteIds: string[] };
}

// SearchPage can be rendered from the home page too when facet filters are applied.

export class SearchPageStore extends AppSubStore implements ISearchPageStore {
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  addToCollectionModal: AddToCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;
  apiQuery: PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult>;
  fastApiQuery: PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult>;
  previousSearchEngineQueryString: string | null = null;
  filters: SearchPageFilter;
  eventContext = EventContext.SearchMultiselectActions;
  showDeepSearchResults = true;

  items: SearchEngineResult[] = [];
  deepSearchItems: SearchEngineResult[] = [];

  static MAX_ITEMS_COUNT = 300;

  // Deduping, sorting, mapping can take some time, so we need to account that in "loader" state.
  private isDedupingItems = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });
    this.filters = new SearchPageFilter(this.store);

    this.fastApiQuery = this.createApiQueryObservable(SearchEngineKind.FAST);
    this.apiQuery = this.createApiQueryObservable(SearchEngineKind.SMART);

    makeObservable<
      this,
      | "generateNoteDropdown"
      | "generateCollectionDropdown"
      | "generateItemRows"
      | "submitClickFeedback"
      | "isDedupingItems"
    >(this, {
      refreshSearchEngineQuery: true,
      loadMoreFastSearchItems: true,
      sortAndGroupNotes: true,
      sortAndGroupCollections: true,
      dedupeIdForItemValue: true,
      dedupeItemsForCarouselValue: true,
      submitClickFeedback: true,
      generateItemRows: true,
      supportsAddToCollectionListAction: observable,
      supportsMoveToTrashListAction: observable,

      addToCollectionModal: false,
      shareSheetModal: false,
      listState: false,
      eventContext: false,
      filters: true,

      // API Query
      previousSearchEngineQueryString: observable,
      resetPreviousQueryString: action,
      createApiQueryObservable: false,
      apiQuery: observable,
      load: false,
      isLoading: computed,
      isSuccess: computed,
      fastApiQuery: true,
      showDeepLoader: computed,

      // Item Rows
      itemRows: computed,
      generateNoteDropdown: false,
      generateCollectionDropdown: false,
      orderedItemIds: computed,
      hasNoItems: computed,
      showDeepSearchResults: observable,
      setShowDeepSearchResults: action,
      deepSearchResults: computed,
      items: observable,
      setupIds: action,
      deepSearchItems: observable,
      isDedupingItems: observable,
      reachedCapacity: computed,
      notAllItemsLoadedButCould: computed,

      // Favorites
      id: computed,
      isFavorited: computed,
      favoriteItemId: computed,
      toggleFavorite: action,
    });

    reaction(
      () => this.filters.searchQuery,
      value => {
        const queryString = this.filters.params.queryString;
        if (!value || !queryString || value === this.previousSearchEngineQueryString) {
          return;
        }

        this.refreshSearchEngineQuery(queryString);
      }
    );

    reaction(
      () => this.filters.dateParams,
      () => this.refreshSearchEngineQuery(this.filters.searchQuery)
    );

    reaction(
      () => [this.apiQuery.isLoading, this.fastApiQuery.isLoading, this.showDeepSearchResults],
      () => {
        this.setupIds();
      }
    );
  }

  refreshSearchEngineQuery(searchQuery: string) {
    this.apiQuery = this.createApiQueryObservable(SearchEngineKind.SMART);
    this.fastApiQuery = this.createApiQueryObservable(SearchEngineKind.FAST);
    this.apiQuery.fetchData();
    this.fastApiQuery.fetchData();
    this.previousSearchEngineQueryString = searchQuery;
    this.showDeepSearchResults = true;
    this.listState.clearSelection();
  }

  createApiQueryObservable(
    searchEngineKind: SearchEngineKind
  ): PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult> {
    return createSearchEngineApiQueryObservable({
      searchEngineKind,
      getSpaceId: () => this.store.spaces.myPersonalSpaceId,
      searchParams: this.filters.params,
      dateParams: this.filters.dateParams,
      apiClient: api,
    });
  }

  load = () => {
    this.fastApiQuery.fetchData();
    this.apiQuery.fetchData();
  };

  get reachedCapacity() {
    return this.items.length >= SearchPageStore.MAX_ITEMS_COUNT;
  }

  get notAllItemsLoadedButCould() {
    return (
      this.reachedCapacity && (this.fastApiQuery.canFetchMoreData || this.apiQuery.canFetchMoreData)
    );
  }

  loadMoreFastSearchItems() {
    if (this.fastApiQuery.isLoading) {
      return;
    }

    this.fastApiQuery.fetchData();
  }

  get showDeepLoader() {
    return this.apiQuery.isLoading;
  }

  get isLoading() {
    return this.apiQuery.isLoading || this.fastApiQuery.isLoading;
  }

  get isSuccess() {
    return this.apiQuery.isSuccess || this.fastApiQuery.isSuccess;
  }

  setShowDeepSearchResults(value: boolean) {
    this.showDeepSearchResults = value;
  }

  get deepSearchResults() {
    return this.items.filter(item => item.isDeepSearch);
  }

  private generateNoteDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: async () => await noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () =>
            this.addToCollectionModal.open({
              noteIds: [noteObservable.id],
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: async () => await noteObservable.moveToTrash(),
        },
      ],
    };
  }

  private generateCollectionDropdown({
    collectionObservable,
  }: {
    collectionObservable: CollectionObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: collectionObservable.id,
              entityKind: ShareSheetEntityKind.Collection,
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: `copy-link-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => {
            actions.copyCollectionLinkToClipboard({ collectionId: collectionObservable.id });
          },
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `delete-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: () =>
            actions.deleteCollectionPermanently({
              collection: collectionObservable,
              store: this.store,
            }),
        },
      ],
    };
  }

  private async submitClickFeedback(
    trackingId: string,
    pageCursor: string | null,
    searchSessionId: string,
    userSpaceId: string
  ) {
    const response = await api.post("/v2/search/feedback", {
      params: {
        query: {
          space_id: userSpaceId,
        },
      },
      body: {
        search_engine_result_id: trackingId,
        search_session_page_cursor: pageCursor,
        session_id: searchSessionId,
        feedback_kind: "CLICK",
      },
    });

    if (response.error) {
      console.warn("Error in submitting the click feedback: ", response.error);
    }
  }

  private generateItemRows(results: SearchEngineResult[]): MdsItemListRowData[] {
    const rows: MdsItemListRowData[] = [];
    let lastHeading = "";

    let notePosition = 0;
    let collectionPosition = 0;
    let carouselPosition = 0;

    const items = this.showDeepSearchResults ? results : results.filter(item => !item.isDeepSearch);

    for (const result of items) {
      switch (result.kind) {
        case "HEADING": {
          const title = (result.value as ApiSchema["SearchEngineResultSectionHeadingValue"]).label;

          // If a following response returns the continuation of the previous heading.
          if (title === lastHeading) break;
          lastHeading = title;
          const row: MdsItemListSectionHeaderRowData = {
            type: MdsItemListRowType.SectionHeader,
            payload: {
              title,
            },
            key: `heading-${title}`,
          };
          if (items.indexOf(result) !== items.length - 1) {
            rows.push(row);
          }
          break;
        }
        case "ITEM": {
          const item = result.value as ApiSchema["SearchEngineResultSectionItemValue"];
          switch (item.kind) {
            case "NOTE": {
              const value = item.value as ApiSchema["SearchEngineResultSectionItemNoteValue"];
              const id = value.note_id;

              const noteObservable = this.store.notes.get(id);
              if (
                !noteObservable ||
                noteObservable.isTrashed ||
                noteObservable.isDeleted ||
                !noteObservable.canAccess
              ) {
                continue;
              }

              const collectionObservables = noteObservable.collectionList.allCollections;

              const hasExtraRows =
                !!value.featured_collection_ids?.length || !!value.highlighted_snippets?.length;

              const searchSessionCursor = value.metadata.search_session_cursor;
              const searchSessionId = this.apiQuery.latestSearchSessionData?.searchSessionId;
              const mdsItem: MdsItem = {
                id,
                kind: MdsItemKind.Note,
                createPreviewState: () =>
                  new ItemPreviewState({
                    store: this.store,
                    id,
                    kind: MdsItemKind.Note,
                  }),
                label: noteObservable.title,
                rightAlignedComponent: (
                  <>
                    {searchSessionId && (
                      <SearchResultVotingButtons
                        itemId={id}
                        searchEngineResultId={value.metadata.search_engine_result_id}
                        pageCursor={searchSessionCursor}
                        searchSessionId={searchSessionId}
                        userSpaceId={this.store.spaces.myPersonalSpaceId}
                        additionalElements={
                          value.metadata.source_debug_info && (
                            <SearchResultDebugInfo
                              rawScoreFromSource={value.metadata.source_debug_info.raw_score}
                              rerankerScore={value.metadata.source_debug_info.reranker_score}
                              preAdjustedScore={value.metadata.source_debug_info.pre_adjusted_score}
                              source={value.metadata.source_debug_info.kind}
                            />
                          )
                        }
                      />
                    )}
                  </>
                ),
                onClick: () => {
                  if (searchSessionId) {
                    this.submitClickFeedback(
                      value.metadata.search_engine_result_id,
                      searchSessionCursor,
                      searchSessionId,
                      this.store.spaces.myPersonalSpaceId
                    );
                  } else {
                    console.warn("No search session id found. Feedback buttons not rendered.");
                  }

                  this.store.navigation.goToNote({ noteId: id });

                  /**
                   * Track the navigation to a note
                   */
                  trackEvent(TrackedEvent.SearchResultsOpenNote, {
                    query: this.filters.searchQuery,
                    sort_by: this.filters.params.sortBy,
                    position: notePosition,
                    note_id: id,
                    note_primary_label: noteObservable.title,
                  });
                },
                sharedBy: noteObservable.sharedBy,
                titleIcon: result.isDeepSearch
                  ? {
                      kind: MdsIconKind.Radar,
                      innerStyles: { Icon: { className: inlineDeepSearchIconStyles } },
                      tooltip: "Deep Search result",
                    }
                  : undefined,
                dateLabel: generateShortDateString(
                  this.filters.params.sortBy === SortByKind.LastCreated
                    ? noteObservable.createdAt
                    : noteObservable.modifiedAt
                ),
                isSpecial: result.isDeepSearch,
                dropdown: this.generateNoteDropdown({ noteObservable }),
                listState: this.listState,
                extraRows: hasExtraRows
                  ? [
                      {
                        id: `note-${id}-content`,
                        content: () => (
                          <MdsItemListRowFeaturedContent
                            collections={sortCollectionsForChips(collectionObservables)}
                            snippet={value.highlighted_snippets?.map(({ text, is_highlight }) => ({
                              text,
                              isHighlight: is_highlight,
                            }))}
                          />
                        ),
                      },
                    ]
                  : undefined,
              };

              rows.push({
                type: MdsItemListRowType.Item,
                key: id,
                payload: mdsItem,
                size: hasExtraRows ? MdsItemListSize.XLarge : MdsItemListSize.Medium,
              });
              notePosition++;
              break;
            }
            case "COLLECTION": {
              const value = item.value as ApiSchema["SearchEngineResultSectionItemCollectionValue"];
              const id = value.collection_id;
              // TODO: delay until render time.
              const collectionObservable = this.store.collections.getCollectionObservableById({
                collectionId: id,
              });
              if (!collectionObservable) break;

              rows.push({
                type: MdsItemListRowType.Item,
                key: id,
                payload: {
                  id,
                  kind: MdsItemKind.Collection,
                  createPreviewState: () =>
                    new ItemPreviewState({
                      store: this.store,
                      id: id,
                      kind: MdsItemKind.Collection,
                    }),
                  label: collectionObservable.label,
                  onClick: () => {
                    this.store.navigation.goToCollection({ collectionId: id });

                    /**
                     * Track the navigation to a collection
                     */
                    trackEvent(TrackedEvent.SearchResultsOpenCollection, {
                      query: this.filters.searchQuery,
                      sort_by: this.filters.params.sortBy,
                      position: collectionPosition,
                      collection_id: id,
                      collection_primary_label: collectionObservable.label,
                    });
                  },
                  sharedBy: collectionObservable.sharedBy,
                  dateLabel: generateShortDateString(
                    this.filters.params.sortBy === SortByKind.LastCreated
                      ? collectionObservable.createdAt
                      : collectionObservable.modifiedAt
                  ),
                  dropdown: this.generateCollectionDropdown({ collectionObservable }),
                  listState: this.listState,
                },
              });
              collectionPosition++;
              break;
            }
          }
          break;
        }
        case "CAROUSEL": {
          const value = result.value as ApiSchema["SearchEngineResultSectionCarouselValue"];
          const carousel: MdsItemListCarouselRowData = {
            type: MdsItemListRowType.Carousel,
            key: `carousel-${rows.length}`,
            payload: {
              items: [],
            },
          };
          for (const item of value.items) {
            switch (item.kind) {
              case "COLLECTION": {
                const { collection_id: collectionId } =
                  item.value as ApiSchema["SearchEngineResultSectionCarouselItemCollectionValue"];
                const collection = this.store.collections.getCollectionObservableById({
                  collectionId,
                });
                if (!collection) break;

                carousel.payload.items.push(
                  getMdsCarouselItemForCollection({
                    collection,
                    store: this.store,
                    position: carouselPosition,
                    query: this.filters.searchQuery,
                    sortBy: this.filters.params.sortBy,
                  })
                );
                carouselPosition++;
                break;
              }
            }
          }
          if (carousel.payload.items.length) {
            rows.push(carousel);
          }
          break;
        }
      }
    }

    if (rows.length && this.notAllItemsLoadedButCould) {
      rows.push({
        type: MdsItemListRowType.Other,
        key: "reached-capacity",
        size: MdsItemListSize.Medium,

        payload: {
          children: <SearchPagePaginationCapacityReached />,
        },
      });
    }

    if (rows.length) {
      rows.push({
        type: MdsItemListRowType.Padding,
        key: "padding",
        payload: { height: 50 },
      });
    }

    return rows;
  }

  async setupIds() {
    runInAction(() => {
      this.isDedupingItems = true;
    });

    const mappedDeepSearchItems = this.apiQuery.items.map(item => ({
      ...item,
      isDeepSearch: true,
    }));

    const items = this.fastApiQuery.items.concat(mappedDeepSearchItems);
    const mainList: SearchEngineResult[] = [];
    const carouselItems: SearchEngineResultSectionCarouselItems[] = [];

    const seenIdsInMainList = new Set<string>();
    const seenIdsInCarousel = new Set<string>();

    // dedupe first + collect unique ids
    for (const item of items) {
      if (item.kind === "CAROUSEL") {
        const value = item.value as SearchEngineResultSectionCarouselValue;
        const dedupedItems = this.dedupeItemsForCarouselValue(value, seenIdsInCarousel);
        carouselItems.push(...dedupedItems);
      } else if (item.kind === "ITEM") {
        const dedupedIds = this.dedupeIdForItemValue(item.value as SearchEngineResultItemValue);
        if (!dedupedIds || seenIdsInMainList.has(dedupedIds)) continue;
        seenIdsInMainList.add(dedupedIds);
        mainList.push(item);
      }
    }

    const listItems: SearchEngineResult[] = [];
    if (!mainList.length && !carouselItems.length) {
      runInAction(() => {
        this.items = [];
        this.isDedupingItems = false;
      });

      return;
    }

    // then get sorted collections
    if (seenIdsInCarousel.size) {
      await this.sortAndGroupCollections(Array.from(seenIdsInCarousel), carouselItems, listItems);
    }

    // then get sorted notes
    if (seenIdsInMainList.size) {
      await this.sortAndGroupNotes(Array.from(seenIdsInMainList), mainList, listItems);
    }

    runInAction(() => {
      this.items = listItems;
      this.isDedupingItems = false;
    });

    if (!this.reachedCapacity) {
      this.load();
    }
  }

  async sortAndGroupNotes(
    ids: string[],
    notesList: SearchEngineResult[],
    listItems: SearchEngineResult[]
  ) {
    const noteTuples = await this.store.notes.search({
      lens: LensKind.All,
      sortBy: this.filters.params.sortBy as NotesSortByKind,
      limit: 10000,
      filter: {
        ...this.filters,
        ids,
      },
    });

    if (noteTuples.length) {
      listItems.push({
        kind: "HEADING",
        value: {
          label: "Notes",
        },
      });

      for (const tuple of noteTuples) {
        const item = notesList.find(item => {
          const noteValue = item.value as SearchEngineResultItemValue;
          const noteValueNoteValue = noteValue.value as SearchEngineResultItemNoteValue;

          return noteValueNoteValue.note_id === tuple[2];
        });
        if (!item) continue;
        listItems.push(item);
      }
    }
  }

  async sortAndGroupCollections(
    ids: string[],
    carouselItems: SearchEngineResultSectionCarouselItems[],
    listItems: SearchEngineResult[]
  ) {
    const collectionTuples = await searchCollections(this.store, {
      sortBy: this.filters.params.sortBy as CollectionSearchSortByKind,
      limit: 10000,
      filter: {
        ...this.filters,
        ids,
      },
      returns: CollectionSearchReturnType.CollectionIndexTuple,
    });

    if (collectionTuples.length) {
      const listCarouselItems = [];
      for (const tuple of collectionTuples) {
        const item = carouselItems.find(item => {
          const collectionValue = item.value as SearchEngineResultItemCollectionValue;
          return collectionValue.collection_id === tuple[2];
        });
        if (!item) continue;
        listCarouselItems.push(item);
      }

      listItems.push(
        {
          kind: "HEADING",
          value: {
            label: "Collections",
          },
        },
        {
          kind: "CAROUSEL",
          value: {
            items: listCarouselItems,
          },
        }
      );
    }
  }

  get itemRows(): MdsItemListRowData[] {
    return this.generateItemRows(this.items);
  }

  dedupeIdForItemValue(value: SearchEngineResultItemValue): string | undefined {
    if (value.kind === "NOTE") {
      const noteValue = value.value as SearchEngineResultItemNoteValue;
      return noteValue.note_id;
    }

    return undefined;
  }

  dedupeItemsForCarouselValue(
    value: SearchEngineResultSectionCarouselValue,
    seenIdsInCarousel: Set<string>
  ) {
    const items = value.items as SearchEngineResultSectionCarouselItems[];
    return items.filter(item => {
      let id: string | undefined;
      if (item.kind === "COLLECTION") {
        const collectionValue = item.value as SearchEngineResultItemCollectionValue;
        id = collectionValue.collection_id;
      }

      if (!id || seenIdsInCarousel.has(id)) return false;
      seenIdsInCarousel.add(id);
      return true;
    });
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get hasNoItems() {
    return (
      !this.itemRows.length &&
      !this.fastApiQuery.isLoading &&
      !this.apiQuery.isLoading &&
      !this.isDedupingItems
    );
  }

  // Favorite Search
  get isFavorited() {
    return this.store.favoriteItems.pool.has(this.favoriteItemId);
  }

  get favoriteItemId() {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get id() {
    return resolveSavedSearchSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      queryString: this.filters.searchQuery,
    });
  }

  toggleFavorite = async () => {
    const savedSearchLabel = this.filters.params.queryString;
    const savedSearchQueryStr = this.filters.searchQuery;

    const existingSavedSearch = this.store.favoriteItems.pool.has(this.favoriteItemId);

    if (existingSavedSearch) {
      await new RemoveSavedSearchFromFavoritesOperation({
        store: this.store,
        payload: { saved_search_id: this.id },
        savedSearchQueryString: savedSearchQueryStr,
      }).execute();
    } else {
      await new AddSavedSearchToFavoritesOperation({
        store: this.store,
        payload: {
          saved_search_query_string: savedSearchQueryStr,
          saved_search_label: savedSearchLabel,
        },
      }).execute();
    }
  };

  resetPreviousQueryString = () => {
    this.previousSearchEngineQueryString = null;
  };
}

const inlineDeepSearchIconStyles = css({
  color: mdsColors().lilac.x200,
});

type SearchEngineResultItemValue = ApiSchema["SearchEngineResultSectionItemValue"];
type SearchEngineResultItemNoteValue = ApiSchema["SearchEngineResultSectionItemNoteValue"];
type SearchEngineResultItemCollectionValue =
  ApiSchema["SearchEngineResultSectionItemCollectionValue"];
type SearchEngineResultSectionCarouselValue = ApiSchema["SearchEngineResultSectionCarouselValue"];
type SearchEngineResultSectionCarouselItems = ApiSchema["SearchEngineResultSectionCarouselItems"];

import { MouseEvent, KeyboardEvent } from "react";
import { numberModule } from "@/modules/number";
import { urlParamsModule } from "@/modules/url-params";
import { INoteObservable } from "@/store/note/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { isEmpty, isString } from "lodash-es";
import { action, computed, makeObservable, observable } from "mobx";

const SELECTED_ITEM_INITIAL_INDEX = 0;

export type CalculateSelectedItemIndex = (args: {
  rowCount: number;
}) => number | { itemId: string };
export type RegisterSelectedItemHandler = (args: { handler?: () => void; itemId?: string }) => void;

export class QuickSearchModalStore extends AppSubStore {
  isOpen = false;
  value = "";
  internalSelectedItemIndex: number = SELECTED_ITEM_INITIAL_INDEX;
  isHoveringOverItemId?: string;
  selectedItemId?: string;
  selectedItemHandler?: ({ event }: { event?: MouseEvent | KeyboardEvent }) => void;
  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      isOpen: observable,
      value: observable,
      internalSelectedItemIndex: observable,
      isHoveringOverItemId: observable,
      selectedItemId: observable,
      selectedItemHandler: observable,
      shouldShowRecentsSection: computed,
      shouldShowSuggestionsSection: computed,
      selectedNoteObservable: computed,
      calculateSelectedItemIndex: false,
      handleHoveringOverItems: action,
      registerSelectedItemHandler: action,
      toggleIsOpen: action,
      openWithQuery: action,
      close: action,
      reset: action,
      handleRef: false,
      handleBlur: false,
      handleValueChange: action,
      handleKeyDown: action,
      handleSearch: action,
      handleAsk: action,
      updateSelectedItemId: action,
    });
  }

  get shouldShowRecentsSection() {
    return isEmpty(this.value);
  }

  get shouldShowSuggestionsSection() {
    return !isEmpty(this.value);
  }

  get selectedNoteObservable(): INoteObservable | undefined {
    if (!this.selectedItemId) {
      return undefined;
    }

    const noteObservable = this.store.notes.getNoteObservableById({
      noteId: this.selectedItemId,
    });

    return noteObservable;
  }

  calculateSelectedItemIndex = ({ rowCount }: { rowCount: number }) => {
    if (this.isHoveringOverItemId) {
      return { itemId: this.isHoveringOverItemId };
    }
    return numberModule.mod({ numberA: this.internalSelectedItemIndex, numberB: rowCount });
  };

  handleHoveringOverItems = ({ itemId }: { itemId?: string }) => {
    this.isHoveringOverItemId = itemId;
    if (!itemId) {
      this.internalSelectedItemIndex = 0;
    }
  };

  registerSelectedItemHandler: RegisterSelectedItemHandler = ({ handler, itemId }) => {
    this.selectedItemHandler = handler;
    this.selectedItemId = itemId;
  };

  toggleIsOpen = () => {
    this.reset();
    this.isOpen = !this.isOpen;
  };

  openWithQuery = ({ query }: { query: string | null }) => {
    if (isString(query)) {
      this.value = query;
    }

    this.isOpen = true;
  };

  close = () => {
    this.isOpen = false;
    this.reset();
  };

  reset = () => {
    this.value = "";
    this.internalSelectedItemIndex = SELECTED_ITEM_INITIAL_INDEX;
    this.isHoveringOverItemId = undefined;
  };

  handleRef = (elem: HTMLTextAreaElement | null) => {
    if (!elem) return;

    elem.focus();

    // Set cursor at the end of text
    const length = elem.value.length;
    elem.setSelectionRange(length, length);
  };

  handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    // Refocus.
    event.target.focus();
  };

  handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.value = event.target.value;
  };

  handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowUp": {
        if (this.isHoveringOverItemId) {
          break;
        }

        event.preventDefault();
        this.internalSelectedItemIndex = this.internalSelectedItemIndex - 1;
        break;
      }

      case "ArrowDown": {
        if (this.isHoveringOverItemId) {
          break;
        }

        event.preventDefault();
        this.internalSelectedItemIndex = this.internalSelectedItemIndex + 1;
        break;
      }

      case "Escape": {
        this.close();
        break;
      }

      case "Enter": {
        event.preventDefault();
        this.selectedItemHandler?.({ event });
      }
    }
  };

  handleSearch = () => {
    const queryString = this.value.trim().replace(/\s+/g, " ");

    if (!queryString) {
      setTimeout(this.close, 0);
      return;
    }

    const activeSearchEngineParams = urlParamsModule.search.parse({
      searchQueryStr: this.store.navigation.activeSearchQuery,
    });

    // erase the query string first to force a re-fetch
    this.store.navigation.goToSearch({
      searchParams: {
        ...activeSearchEngineParams,
        queryString: "",
      },
    });

    setTimeout(() => {
      this.store.navigation.goToSearch({
        searchParams: {
          ...activeSearchEngineParams,
          queryString,
        },
      });
    }, 10);

    setTimeout(this.close, 0);
  };

  handleAsk = async () => {
    const queryString = this.value.trim().replace(/\s+/g, " ");

    await this.store.chatMessages.sendNewMessage(queryString);

    if (queryString) {
      this.store.navigation.goToChat();
    }

    setTimeout(this.close, 0);
  };

  // TODO: remove Recents and use React state instead, then cleanup this
  updateSelectedItemId = (itemId?: string) => {
    this.selectedItemId = itemId;
  };
}
